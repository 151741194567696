import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, media, Button, Box, Typography } from "@anyfin/ui";
import styled from "styled-components";

const StyledModal = styled(Modal)`
  border-radius: 8px;
  max-width: 600px;
  padding: 2em 1em;
`;

const StyledButton = styled(Button)`
  ${media.only.phone`
    width: 100%;
  `}
`;

const SignScriveButton = ({
  handleSubmit,
  isSigning,
  cancelSign,
  signOfferData,
  disabled,
  fluid
}) => {
  const { t } = useTranslation("offer");
  const [manualLink, setManualLink] = useState(null);
  const openerRef = useRef(null);
  const timerRef = useRef(null);

  /**
   * Try opening a new tab from a url and listen
   * for window closing
   * @param {String} url
   */
  const tryWindowOpenUrl = url => e => {
    const popup = window.open(url, "offerSign");
    if (!popup) return null;
    openerRef.current = popup;
    popup.focus();

    // If the new window is closed by user, signing process is canceled
    timerRef.current = setInterval(() => {
      let href = null;
      try {
        href = openerRef.current?.location?.href;
      } catch (e) {
        // No need to do anything here
      }
      if (href) {
        // `href` is available meaning the user returned to our domain in the external window! Session is finished.
        clearInterval(timerRef.current);
      } else {
        // User closed the external window before the external session was finished
        openerRef.current && openerRef.current.closed && cancelSign();
      }
    }, 500);

    return popup;
  };

  useEffect(() => {
    if (isSigning && signOfferData.authUrl && !openerRef.current) {
      const popup = tryWindowOpenUrl(signOfferData.authUrl)();

      // Detect "popup" blocking
      // If blocked, offer a popup to open the new window "manually" and still listen to closed events
      if (!popup) {
        setManualLink(signOfferData.authUrl);
      }
    }
    if (openerRef.current && !isSigning) {
      clearInterval(timerRef.current);
      openerRef.current.close();
      openerRef.current = undefined;
      window.focus();
    }
    // eslint-disable-next-line
  }, [isSigning, signOfferData, openerRef]);

  return (
    <>
      <StyledButton
        onClick={handleSubmit}
        disabled={disabled || isSigning}
        loading={isSigning}
        iconRight={isSigning ? "Loading" : "ArrowRight"}
        fluid={fluid}
        rounded
        elevated
        fontWeightMedium
        rightAlignedTitle
        style={{ height: "4rem" }}
      >
        {t("step_3.accept_with_bank")}
      </StyledButton>

      {/* Popup modal to redirect people to new page on iOS/Safari devices */}
      <StyledModal open={Boolean(manualLink)} background="background">
        <Box marginBottom="medium">
          <Typography.H6 as="h2">{t("step_3.popup_header")}</Typography.H6>
        </Box>
        <Box marginBottom="medium">
          <Typography>{t("step_3.popup_paragraph")}</Typography>
        </Box>
        <Button
          rounded
          fluid
          iconRight="ArrowForward"
          onClick={e => tryWindowOpenUrl(manualLink)(e) && setManualLink(null)}
        >
          {t("offer:greeting.continue")}
        </Button>
        <Button
          fluid
          iconRight="X"
          style={{ backgroundColor: "transparent" }}
          onClick={() => {
            setManualLink(null);
            cancelSign();
          }}
        >
          {t("common:cancel")}
        </Button>
      </StyledModal>
    </>
  );
};

export default SignScriveButton;
