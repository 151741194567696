import React from "react";
import { Box, Icon, ListItem, Typography } from "@anyfin/ui";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { formatMoney } from "@anyfin/number-formatter";
import { useCountry } from "@anyfin/number-formatter/components";
import Section from "../../../components/Section";

const StyledListItem = styled(ListItem)`
  background-color: ${props => {
    return props.theme.colors.success.light;
  }};
  border-radius: 1.5rem;
`;

const IconContainer = styled(Box)`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => {
    return props.theme.colors.success.light;
  }};
`;

interface ReferralProps {
  discount: number;
  isReferred: boolean;
}
const Referral = ({ isReferred, discount }: ReferralProps) => {
  const { t } = useTranslation("offer");
  const [country] = useCountry();
  return (
    <Section>
      <Box marginLeft={0.5} marginRight={0.5}>
        <StyledListItem
          floating={false}
          dataLeft={
            <IconContainer>
              <Icon icon="Check" size={1.25} />
            </IconContainer>
          }
        >
          <Trans
            t={t}
            i18nKey={
              isReferred
                ? "offer_promo_code.added"
                : "offer_promo_code.discount_added"
            }
            values={{
              discount: formatMoney(discount, country),
              code: "Something"
            }}
            components={[<Typography key={0} medium />]}
          ></Trans>
        </StyledListItem>
      </Box>
    </Section>
  );
};

export default Referral;
