import * as Sentry from "@sentry/browser";
import { gql } from "@apollo/client";
import { trackEvent } from "../../utils/segment";
import { getTrackingProps } from "./OfferPage";
import apolloClient from "../../apollo";
import { CURRENCY_CODES } from "../../utils/countries";
// eslint-disable-next-line no-unused-vars
import type { CustomerOfferQueryType } from "./hooks/useRefinanceOffer";

const REGISTER_OFFER_VIEWED = gql`
  mutation RegisterOfferViewedEvent($customerId: ID!, $data: JSON) {
    registerOfferViewedEvent(customerId: $customerId, data: $data)
  }
`;

interface Totals {
  financingCost: number;
  oldFinancingCost: number;
  oldInterestRate: number;
  interestRate: number;
  amount: number;
  savedAmount: number;
  savingImprovement: number;
  totalOffers: number;
}

export const trackOfferViewed = async (
  customer: CustomerOfferQueryType["customer"],
  totals: Totals,
  currency: CURRENCY_CODES
): Promise<void> => {
  const { offers, offersVariation, applications } = customer;

  const application = Array.isArray(applications)
    ? applications[applications.length - 1]
    : applications;

  const id = offers.map(o => o.id).join(",");
  let trackingProps = {
    id,
    order_id: id,
    affiliate: application?.affiliate || null
  };

  // Possible failure of calculating tracking props across offers
  try {
    trackingProps = {
      ...trackingProps,
      ...getTrackingProps(offers, offersVariation, totals, currency)
    };
  } catch (e) {
    Sentry.captureException(e, {
      tags: {
        component: "trackOfferViewed"
      }
    });
  }

  // we track double due to delay of info transfer from Segment to our replica db
  trackEvent("Offer Viewed", trackingProps);

  await apolloClient
    .mutate({
      mutation: REGISTER_OFFER_VIEWED,
      fetchPolicy: "no-cache",
      variables: {
        customerId: customer.id,
        data: {
          applicationIds: offers.map(o => o.applicationId),
          offerIds: offers.map(o => o.id),
          variant: offersVariation,
          isABTest: false
        }
      }
    })
    .catch(e => {
      Sentry.captureException(e);
    });
};
