import * as React from "react";
import styled, { keyframes, css } from "styled-components";
import Section from "../../../components/Section";
import { media, Typography, Box, Icon } from "@anyfin/ui";
import { useCountry } from "@anyfin/number-formatter/components";
import CopyContainer from "../../../components/CopyContainer";
import { formatPercent } from "@anyfin/number-formatter";
import BottomStacks from "../components/BottomStacks";
import { useTranslation } from "react-i18next";
import { CustomerType } from "../../../types/types";
import { OfferTotalsType } from "../types";

const StyledSection = styled(Section)`
  background-color: ${({ theme }) => theme.colors.yellow.letters?.B};
`;

const READ_MORE_OFFSET = "83.2px";
const StyledBox = styled(Box)`
  height: calc(67vh - ${READ_MORE_OFFSET});
  justify-content: flex-end;

  padding: 0.5rem;

  ${media.up.tablet(css`
    height: calc(58vh - ${READ_MORE_OFFSET});
  `)}
`;

const bounce = keyframes`
  from { transform: translateY(-10px); }
  to { transform: translateY(10px); }
`;

const ReadMoreContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation: ${bounce} 1s cubic-bezier(0.37, 0, 0.63, 1) infinite alternate;

  &:hover {
    cursor: pointer;
  }
`;

const ArrowContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-left: 0.25rem;
`;

const StyledIcon = styled(Icon)`
  height: 1.25em;
  width: 1.25em;

  &:not(:last-of-type) {
    margin-bottom: -0.95em;
  }
`;

const StyledBottomStacksSection = styled(Section)`
  padding: 0;
`;

interface GreetingProps {
  customer: CustomerType;
  totals: OfferTotalsType;
  children: React.ReactNode;
  readMore: () => void;
}

const Greeting = ({ totals, children, readMore }: GreetingProps) => {
  const [country] = useCountry();
  const { t } = useTranslation("offer");

  return (
    <>
      <StyledSection>
        <StyledBox align={"center"}>
          <CopyContainer>
            <Typography.H7
              style={{
                whiteSpace: "break-spaces",
                textAlign: "center"
              }}
            >
              {t("offer_language.saving_relative")}
            </Typography.H7>
            <Typography
              size={6}
              medium
              style={{
                textAlign: "center"
              }}
            >
              {formatPercent(totals.savingImprovement, country, {
                decimals: true
              }).replace(/\s/g, "")}
            </Typography>
          </CopyContainer>
          <Box marginTop={5}>{children}</Box>
        </StyledBox>
      </StyledSection>
      <StyledBottomStacksSection>
        <BottomStacks totalStacks={3}></BottomStacks>
      </StyledBottomStacksSection>
      <ReadMoreContainer paddingTop={1.5} paddingBottom={1} onClick={readMore}>
        {t("read_more")}
        <ArrowContainer>
          <StyledIcon icon="ChevronDown"></StyledIcon>
          <StyledIcon icon="ChevronDown"></StyledIcon>
        </ArrowContainer>
      </ReadMoreContainer>
    </>
  );
};

export default Greeting;
