// Supported country codes in App
export enum COUNTRY_CODES {
  SE = "SE",
  FI = "FI",
  DE = "DE",
  NO = "NO"
}

// Supported origination currency codes in App
export enum CURRENCY_CODES {
  EUR = "EUR",
  SEK = "SEK"
}
