import React from "react";
import Modal from "../../../../components/Modal";
import { Typography, Box, theme } from "@anyfin/ui";
import CopyContainer from "../../../../components/CopyContainer";
import { useTranslation } from "react-i18next";
import { formatMoney } from "@anyfin/number-formatter";
import { OfferType } from "../../../../types/types";
import { sumBy } from "lodash";
import { useCountry } from "@anyfin/number-formatter/components";
import ProviderCard from "../../../../components/ProviderCard";
import needHelp from "../../../../utils/needHelp";

const getMonthlyPayment = (offers: Array<OfferType>) => [
  sumBy(offers, "monthlyPayment"),
  sumBy(offers, "originalMonthlyPayment")
];

export interface OfferAdjustmentModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  offers: Array<OfferType>;
}

export const OfferAdjustedDurationModal = ({
  isOpen,
  toggleModal,
  offers
}: OfferAdjustmentModalProps) => {
  const { t } = useTranslation("offer");
  const [country] = useCountry();
  const [newMonthly, oldMonthly] = getMonthlyPayment(offers);

  return (
    <Modal open={isOpen} toggleModal={toggleModal} title="">
      <CopyContainer paddingLeft={1.5} paddingRight={1.5} paddingTop={2}>
        <Typography size={2} style={{ marginBottom: "1rem" }} $as="h2">
          {t("offer_adjustment_details.adjusted_duration_title")}
        </Typography>
        <Typography size={1} style={{ whiteSpace: "break-spaces" }}>
          {t("offer_adjustment_details.adjusted_duration_description", {
            monthlyPayment: formatMoney(newMonthly, country),
            oldMonthlyPayment: formatMoney(oldMonthly, country)
          })}
        </Typography>
      </CopyContainer>
      <Box marginTop={2}>
        {offers.map(offer => (
          <ProviderCard
            key={offer.id}
            color="transparent"
            provider={offer.lender}
            description={formatMoney(offer.amount, country)}
            onClick={undefined}
            labelRight={undefined}
            iconRight={undefined}
            showAvatarBorder={undefined}
            isDemo={undefined}
          ></ProviderCard>
        ))}
      </Box>
      <CopyContainer marginTop={1} onClick={needHelp}>
        <Typography
          $as="span"
          color={theme.colors.textLight.medium}
          style={{ textDecoration: "underline" }}
        >
          {t("offer_adjustment_details.something_wrong")}
        </Typography>
      </CopyContainer>
    </Modal>
  );
};

export const OfferBalanceSplitModal = ({
  isOpen,
  toggleModal,
  offers
}: OfferAdjustmentModalProps) => {
  const { t } = useTranslation("offer");

  return (
    <Modal open={isOpen} toggleModal={toggleModal} title="">
      <CopyContainer paddingLeft={1.5} paddingRight={1.5} paddingTop={2}>
        <Typography size={2} style={{ marginBottom: "1rem" }} $as="h2">
          {t("offer_adjustment_details.balance_split_title")}
        </Typography>
        <Typography size={1} style={{ whiteSpace: "break-spaces" }}>
          {t("offer_adjustment_details.balance_split_description")}
        </Typography>
      </CopyContainer>
      <Box marginTop={2}>
        {offers.map(offer => (
          <ProviderCard
            key={offer.id}
            color="transparent"
            provider={offer.lender}
            description={undefined}
            onClick={undefined}
            labelRight={undefined}
            iconRight={undefined}
            showAvatarBorder={undefined}
            isDemo={undefined}
          ></ProviderCard>
        ))}
      </Box>{" "}
      <CopyContainer marginTop={1} onClick={needHelp}>
        <Typography
          $as="span"
          color={theme.colors.textLight.medium}
          style={{ textDecoration: "underline" }}
        >
          {t("offer_adjustment_details.something_wrong")}
        </Typography>
      </CopyContainer>
    </Modal>
  );
};

export const OttoModal = ({
  isOpen,
  toggleModal
}: OfferAdjustmentModalProps) => {
  const { t } = useTranslation("offer");

  return (
    <Modal open={isOpen} toggleModal={toggleModal} title="">
      <CopyContainer paddingLeft={1.5} paddingRight={1.5} paddingTop={2}>
        <Typography size={2} style={{ marginBottom: "1rem" }} $as="h2">
          {t("offer_adjustment_details.otto_title")}
        </Typography>
        <Typography size={1} style={{ whiteSpace: "break-spaces" }}>
          {t("offer_adjustment_details.otto_description")}
        </Typography>
      </CopyContainer>
    </Modal>
  );
};
