import React from "react";
import { useTranslation } from "react-i18next";
import { media, Button } from "@anyfin/ui";
import styled from "styled-components";
import { GERMANY_OFFER_SIGN_ID, downloadApp } from "../../../../utils/client";

const StyledButton = styled(Button)`
  ${media.only.phone`
    width: 100%;
  `}
`;

const SignInAppButton = ({ fluid, disabled }) => {
  const { t } = useTranslation("offer");

  return (
    <>
      <StyledButton
        onClick={() => downloadApp(GERMANY_OFFER_SIGN_ID)}
        iconRight="ArrowRight"
        fluid={fluid}
        rounded
        elevated
        disabled={disabled}
      >
        {t("step_3.sign_in_app")}
      </StyledButton>
    </>
  );
};

export default SignInAppButton;
