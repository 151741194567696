import React from "react";
import { useTranslation } from "react-i18next";
import { media, Button } from "@anyfin/ui";
import styled from "styled-components";

const StyledButton = styled(Button)`
  ${media.only.phone`
    width: 100%;
  `}
`;

const SignInfoCertButton = props => {
  const { t } = useTranslation("offer");
  const { handleSubmit, isSigning } = props;

  return (
    <StyledButton
      onClick={handleSubmit}
      disabled={isSigning}
      loading={isSigning}
      iconRight={isSigning ? "Loading" : "ArrowRight"}
      rounded
      elevated
      fontWeightMedium
      rightAlignedTitle
      style={{ height: "4rem" }}
      {...props}
    >
      {t("pre_offer_sign.infocert.intro.start_signing")}
    </StyledButton>
  );
};

export default SignInfoCertButton;
