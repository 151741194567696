import { media, Box, Notification, Typography, LogoMark } from "@anyfin/ui";
import styled from "styled-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import type { RouteComponentProps } from "react-router-dom";
import { AuthContext } from "../../auth";
import LoginOTPCode from "../../auth/components/LoginOTPCode";

import Section from "../../components/Section";
import { LocaleCodeURL } from "../../utils/i18n";
import LoginBankID from "../Login/LoginBankID";
import LoginScrive from "../Login/LoginScrive";
import OfferPage from "./OfferPage";
import { ScriveSource } from "../../types/types";
import { useAutomaticOfferLogin } from "./hooks/useAutomaticOfferLogin";
import { Loader } from "../../components/Loader";

const StyledLogo = styled(LogoMark)`
  height: 35px;
  width: 36px;
  padding-bottom: 1em;

  ${media.up.desktop`
      height: 64px;
      width: 64px;
    `};
`;

interface RouteParams {
  locale: LocaleCodeURL;
}

const UniversalOfferPage = (props: RouteComponentProps<RouteParams>) => {
  const { t } = useTranslation("auth");
  const auth = useContext(AuthContext);
  const {
    location,
    match: { params }
  } = props;
  const { customerId, loading } = useAutomaticOfferLogin(location);

  const getLoginComponent = (locale: string) => {
    switch (locale) {
      case "sv_SE":
        return LoginBankID;
      case "nb_NO":
        return LoginScrive;
      default:
        return LoginOTPCode;
    }
  };

  if (loading) return <Loader />;

  if (auth.loggedIn) {
    return <OfferPage {...props} />;
  } else {
    const LoginComponent = getLoginComponent(params.locale);

    return (
      <Section>
        <Box>
          <Box>
            <StyledLogo />
          </Box>
          <Box
            style={{
              height: "110px"
            }}
            color="alpha.100"
          ></Box>
          <Box
            style={{
              height: "180px",
              backgroundImage:
                "radial-gradient(120% 120px at 50% 50px, #F4F0EB 75%, transparent 75%)"
            }}
          >
            <Typography variant="h5" style={{ textAlign: "center" }}>
              {t("pre_login_header")}
            </Typography>
          </Box>
        </Box>
        <Box
          marginTop={"large"}
          marginBottom={"6x"}
          marginRight={"medium"}
          marginLeft={"medium"}
        >
          <LoginComponent
            source={ScriveSource.universal}
            customerId={customerId}
            onSuccess={token => auth.login(token)}
          >
            <Notification>{t("session_expired.offer")}</Notification>
          </LoginComponent>
        </Box>
      </Section>
    );
  }
};

export default UniversalOfferPage;
