import React from "react";
import styled from "styled-components";

interface StyledSvgProps {
  totalStacks: number;
  order: number;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledSvg = styled.svg<StyledSvgProps>`
  aspect-ratio: 375/142;
  width: 100%;
  height: auto;
  z-index: ${({ totalStacks, order }) => totalStacks - order};
  margin-top: ${({ order }) =>
    order === 0 ? "-1px" : order === 1 ? "-31%" : "-23%"};
  filter: ${({ order, totalStacks }) =>
    order + 1 === totalStacks
      ? "drop-shadow(0px 16px 10px rgba(0,0,0, 0.1))"
      : `drop-shadow(0px 16px 8px rgba(255, 183, 68, .6))`};
`;

interface BottomStackProps {
  totalStacks: number;
  order: number;
}

const BottomStack = ({ totalStacks, order }: BottomStackProps) => {
  return (
    <StyledSvg
      totalStacks={totalStacks}
      order={order}
      width="375"
      height="142"
      viewBox={`0 ${order === 0 ? 0 : -30} 375 142`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M375.085 -0.0790176V-83.999H0.0849609V-0.149017L130.755 61.351C162.025 76.061 213.175 76.061 244.445 61.351V61.401L375.085 -0.0790176Z"
        fill="#FFD36F"
      />
      <path
        d="M375.085 -0.0880737L244.445 61.3919V61.3419C213.175 76.0519 162.015 76.0519 130.755 61.3419L0.0849609 -0.148071V28.7419L130.755 90.2419C162.025 104.952 213.175 104.952 244.445 90.2419L375.085 28.7719V-0.0880737Z"
        fill="#FFB744"
      />
    </StyledSvg>
  );
};

interface BottomStacksProps {
  totalStacks: number;
}
const BottomStacks = ({ totalStacks }: BottomStacksProps) => {
  return (
    <StyledContainer>
      {Array(totalStacks)
        .fill(null)
        .map((_, i) => (
          <BottomStack
            key={i}
            totalStacks={totalStacks}
            order={i}
          ></BottomStack>
        ))}
    </StyledContainer>
  );
};

export default BottomStacks;
