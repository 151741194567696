import React from "react";
import PropTypes from "prop-types";
import idx from "idx";

const ProviderAvatar = ({
  provider,
  size = 40,
  showBorder,
  demoProvider,
  ...rest
}) => {
  const originalSize = 32;

  const acronym = idx(provider, _ => _.brandAttributes.acronym);
  const backgroundColor = idx(provider, _ => _.brandAttributes.backgroundColor);
  const foregroundColor = idx(provider, _ => _.brandAttributes.foregroundColor);

  const acronymLength = acronym ? acronym.length : 1;
  const fontSize = originalSize / (acronymLength > 2 ? 3 : 2);

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill="none"
      {...rest}
    >
      <circle
        stroke={showBorder ? "#e0e0e0" : null}
        cx={originalSize / 2}
        cy={originalSize / 2}
        r={originalSize / 2}
        fill={backgroundColor || "#fff"}
      />
      <text
        x="50%"
        y="50%"
        fill={foregroundColor || "#000"}
        fontSize={fontSize}
        fontWeight="bold"
        fontFamily="inherit"
        textAnchor="middle"
        alignmentBaseline="central"
        dominantBaseline="central"
      >
        {demoProvider || acronym || provider.name.charAt(0).toUpperCase()}
      </text>
    </svg>
  );
};

ProviderAvatar.propTypes = {
  provider: PropTypes.object,
  size: PropTypes.number,
  showBorder: PropTypes.bool
};

export default ProviderAvatar;
