import { useEffect, useMemo } from "react";
import * as Sentry from "@sentry/browser";
import { generatePath } from "react-router";
import compose from "lodash/flowRight";
import { useTranslation } from "react-i18next";
import sumBy from "lodash/sumBy";
import meanBy from "lodash/meanBy";
import { Notification, Box } from "@anyfin/ui";
import styled from "styled-components";

import { withAuth } from "../../auth";
import { usePolicy } from "../../utils/policy";
import { identify } from "../../utils/segment";
import OfferForm from "./OfferForm";
import { SUPPORTED_LANGUAGES } from "../../utils/i18n";
import LogoBar from "../../components/LogoBar";
import Section from "../../components/Section";
import NoOfferPage from "./NoOfferPage";
import useRefinanceOffers from "./hooks/useRefinanceOffer";
import { OfferSignContextProvider } from "./providers/OfferSignContext";
import { Loader } from "../../components/Loader";
import { isPreview } from "../../utils/routes";

/**
 * Prepare data for showing the offer
 * If customer is not there, this component supports falling back to offerstoken
 */
const OfferPage = ({ auth, history, match }) => {
  const { i18n } = useTranslation("offer");
  const { loading, error, data, offers } = useRefinanceOffers(
    match.params.offersToken
  );
  const {
    data: policyData,
    error: policyError,
    loading: policyLoading
  } = usePolicy({
    skip: !data?.customer,
    variables: { countryCode: data?.customer?.countryCode }
  });

  const customer = data?.customer;

  const totals = useMemo(() => {
    if (!customer || !offers)
      return {
        financingCost: 0,
        oldFinancingCost: 0,
        oldInterestRate: 0,
        interestRate: 0,
        amount: 0,
        savedAmount: 0,
        savingImprovement: 0,
        totalOffers: 0
      };

    const oldFinancingCost = sumBy(offers, "oldFinancingCost");
    const financingCost = sumBy(offers, "financingCost");
    const savedAmount = sumBy(offers, "savedAmount");
    const amount = sumBy(offers, "amount");
    const savingImprovement = Math.round(
      (1 - financingCost / oldFinancingCost) * 100
    );

    const discountAmount =
      offers.find(offer => offer.promoCode)?.promoCode?.amount || 0;

    const totalOffers = offers.length;

    const oldInterestRate = meanBy(offers, "oldInterestRate");
    const interestRate = meanBy(offers, "interestRate");
    const newRate = offers[0]?.interestRate;

    return {
      financingCost,
      oldFinancingCost,
      oldInterestRate,
      interestRate,
      amount,
      discountAmount,
      savedAmount,
      savingImprovement,
      totalOffers,
      newRate
    };
  }, [customer]);

  useEffect(() => {
    if (customer) {
      if (!isPreview(match)) {
        if (!auth.loggedIn) {
          identify(customer);
        }
      }

      // If url Locale is not the default for this customer's country redirect to proper locale URL
      // @TODO move this logic to a middleware route for all customer-specific pages?
      const localeForCustomer = Object.values(SUPPORTED_LANGUAGES)
        .find(lang => lang.country === customer.countryCode)
        ?.code?.replace("-", "_");

      if (localeForCustomer !== match.params.locale) {
        // Generate new path for this route (it can render this page from two routes),
        // overriding the locale param with the correct locale
        const newPath = generatePath(match.path, {
          ...match.params,
          locale: localeForCustomer
        });
        history.replace(newPath);
      }
    }
  }, [
    auth.loggedIn,
    customer,
    i18n,
    totals,
    history,
    match,
    match.params.offersToken
  ]);

  if (loading || policyLoading) return <LoadingView />;
  if (error || policyError || customer === null) {
    return <OnErrorView error={error || policyError || `Invalid customer`} />;
  }
  // Policy loading happens after customer is fetched
  if (!policyData?.policy) return null;
  if (!offers?.length) {
    return <NoOfferPage match={match} />;
  }

  const promoCodeAdded = offers.some(offer => offer.hasDiscountCode) || false;
  const isReferred = offers.some(offer => offer.application?.referrer) || false;

  const showDiscountImpact =
    customer?.features["experiment:show_discount_impact_on_offer"] === "v1";

  return (
    <OfferSignContextProvider>
      <OfferForm
        policy={policyData?.policy}
        customer={customer}
        offersToken={customer.offersToken || match.params.offersToken}
        offers={offers}
        totals={totals}
        promoCodeAdded={promoCodeAdded}
        nextCurrentPayments={customer.nextPayments}
        showDiscountImpact={showDiscountImpact}
        isReferred={isReferred}
      />
    </OfferSignContextProvider>
  );
};

// Tracking props for Offer Viewed and Offer Signed
export const getTrackingProps = (offers, variation, totals, currency) => {
  return {
    promoCode:
      offers
        .filter(o => o.hasDiscountCode)
        // We don't have access to the actual discount code so use dummy
        .map(() => "CODE_NAME_NOT_AVAILABLE")
        .join(",") || undefined, // if empty string set undefined so prop disappears
    amount: sumBy(offers, "amount"),
    savedAmount: sumBy(offers, "savedAmount"),
    relativeSaving: totals.savingImprovement,
    offersSavingVariant: "effective_apr",
    savingVariant: variation,
    currency,
    offers: offers.map(
      ({
        applicationId: id,
        lender,
        amount,
        interestRate: rate,
        savedAmount,
        externalStatement: statement
      }) => ({
        id,
        lenderName: lender.name,
        lenderId: lender.id,
        amount,
        savedAmount,
        rate,
        loanType: statement?.loanType
      })
    )
  };
};

const OnErrorView = props => {
  useEffect(() => {
    Sentry.captureException(props.error, {
      tags: {
        component: "OnErrorView"
      }
    });
  }, []);
  const { t } = useTranslation("offer");
  return (
    <Section>
      <LogoBar />
      <Box marginTop="xxlarge" marginBottom={7} padding="medium">
        <Notification iconLeft="Alert">{t("error_message")}</Notification>
      </Box>
    </Section>
  );
};

const LoadingView = () => {
  return (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export default compose(withAuth)(OfferPage);
