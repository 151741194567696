import { Box, Button, Input, InputGroup } from "@anyfin/ui";
import { gql, useMutation } from "@apollo/client";
import {
  useState,
  FormEvent,
  ReactNode,
  useEffect,
  useCallback,
  useRef
} from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ScriveSource } from "../../types/types";

interface LoginScriveProps {
  onSuccess: (token: string) => void;
  children?: ReactNode;
  source?: ScriveSource; // source is introduce to recognize the source which trigger Scrive login ("mypages","universal")
}

const SCRIVE_SOURCE = {
  mypages: "/nb_NO/login",
  universal: "/nb_NO/offer"
};

const LoginScrive = ({ onSuccess, children, source }: LoginScriveProps) => {
  const [t] = useTranslation("auth");
  const location = useLocation();
  const scriveRef = useRef<boolean>(false);
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState("");
  const [ssn, setSsn] = useState("");

  const [startLoginScrive] = useMutation(START_LOGIN_SCRIVE, {
    context: { service: "gateway" }
  });

  const [collectLoginScrive] = useMutation(COLLECT_LOGIN_SCRIVE, {
    context: { service: "gateway" }
  });

  useEffect(() => {
    const param = new URLSearchParams(location.search);
    const transactionId = param.get("transaction_id");
    const status = param.get("success") === "true"; // here the status value is a string
    if (
      source &&
      status &&
      transactionId &&
      !scriveRef.current &&
      SCRIVE_SOURCE[source]
    ) {
      scriveRef.current = true;
      collectLoginScriveByTransaction(transactionId);
      return () => {
        scriveRef.current = false;
      };
    }
  }, [location.search, scriveRef.current]);

  const collectLoginScriveByTransaction = useCallback(
    async (params: string) => {
      setInProgress(true);
      try {
        const { data } = await collectLoginScrive({
          variables: {
            sessionId: params
          }
        });
        if (data.collectLoginScrive && data.collectLoginScrive.accessToken) {
          onSuccess(data.collectLoginScrive.accessToken);
        } else {
          throw Error("Authentication Failed!");
        }
      } catch (error) {
        setError(t("bankid.errors.generic"));
      } finally {
        setInProgress(false);
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      setInProgress(true);
      const personalIdentifier = ssn.replace(/\D/g, "");
      try {
        const { data } = await startLoginScrive({
          variables: { personalIdentifier, source }
        });
        if (data.startLoginScrive && data.startLoginScrive.accessUrl) {
          window.location.replace(data.startLoginScrive.accessUrl);
        } else {
          throw Error("Authentication Failed!");
        }
      } catch (error) {
        setError(t("bankid.errors.invalid_pno"));
      } finally {
        setInProgress(false);
      }
    },
    [ssn]
  );

  return (
    <form onSubmit={handleSubmit}>
      {children}
      <Box marginBottom="large">
        <InputGroup
          label={t("bankid.personal_identifier_label")}
          tone={error ? "error" : "neutral"}
          message={error}
        >
          <Input
            name="ssn"
            placeholder={t("bankid.personal_identifier_placeholder")}
            value={ssn}
            onChange={e => setSsn(e.currentTarget.value)}
            type="number"
            pattern="[0-9]*"
          />
        </InputGroup>
      </Box>
      <Button
        type="submit"
        disabled={inProgress}
        loading={inProgress}
        iconRight="ArrowRight"
        // iconLeft="BankidFilled"
        // @ts-expect-error Button types are wrong
        rounded
        fluid
        elevated
      >
        {!inProgress ? t("bankid.button") : t("bankid.in_progress")}
      </Button>
    </form>
  );
};

const START_LOGIN_SCRIVE = gql`
  mutation startLoginScrive($personalIdentifier: String, $source: String) {
    startLoginScrive(personalIdentifier: $personalIdentifier, source: $source) {
      sessionId
      accessUrl
      status
    }
  }
`;

const COLLECT_LOGIN_SCRIVE = gql`
  mutation collectLoginScrive($sessionId: String!) {
    collectLoginScrive(sessionId: $sessionId) {
      accessToken
      refreshToken
    }
  }
`;

export default LoginScrive;
