import { localeRegex } from "./i18n";

/**
 * "Absolute url", accessible from any page, regardless of country
 * note: its completely crazy this can not be done with react-router out of the box
 * @param {Object} match react-router match object
 */
export const homeUrl = ({ url }) =>
  url.match(localeRegex) && `/${url.match(localeRegex)[1]}/home`;

export const isPreview = match => {
  return match?.params[0] === "preview";
};
