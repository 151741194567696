/* eslint-disable camelcase */
import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import { CountryCode, formatDate, LocaleCode } from "@anyfin/number-formatter";

import loadResources from "../locales";
import noop from "lodash/noop";

interface LanguageMap {
  country: Uppercase<CountryCode> | null;
  code: LocaleCode;
  label: string;
}

// How it's parsed from url's
export enum LocaleCodeURL {
  en_GB = "en_GB",
  sv_SE = "sv_SE",
  de_DE = "de_DE",
  fi_FI = "fi_FI",
  nb_NO = "nb_NO"
}

export const SUPPORTED_LANGUAGES: Record<LocaleCodeURL, LanguageMap> = {
  en_GB: { country: null, code: "en-GB", label: "English" },
  sv_SE: { country: "SE", code: "sv-SE", label: "Svenska" },
  de_DE: { country: "DE", code: "de-DE", label: "Deutsch" },
  fi_FI: { country: "FI", code: "fi-FI", label: "Suomi" },
  nb_NO: { country: "NO", code: "nb-NO", label: "Bokmål" }
};

// eslint-disable-next-line prefer-regex-literals
export const localeRegex = new RegExp(/^\/((\w{2})_(\w{2}))\/?/);
export const countryFromUrl = (url: string) => {
  return url.match(localeRegex)?.[3] as LanguageMap["country"] | null;
};

/**
 * Get locale part from url, convert to compatible i18next locale
 * @param {String} url
 */
export const localeFromUrl = (url: string): LanguageMap["code"] => {
  const locale =
    url.match(localeRegex) && (url.match(localeRegex)?.[1] as LocaleCodeURL);
  if (!locale) return "sv-SE";
  return (
    (SUPPORTED_LANGUAGES[locale] && SUPPORTED_LANGUAGES[locale]?.code) ??
    "sv-SE"
  );
};

export const setup = async () => {
  const lng = localeFromUrl(window.location.pathname);
  const resources = (await loadResources()) as Resource;
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init(
      {
        resources,
        lng,
        fallbackLng: [SUPPORTED_LANGUAGES.en_GB.code],
        defaultNS: "common", // use entries from common.json by default
        fallbackNS: "common", // If key does not exist, look in common
        keySeparator: ".", // Nest keys within a namespace using dot
        pluralSeparator: "-",
        contextSeparator: "-",
        interpolation: {
          format: (value, _, lng) => {
            const country =
              Object.values(SUPPORTED_LANGUAGES).find(
                entry => entry.code === (lng as LocaleCode)
              )?.country ?? "SE";
            if (value instanceof Date) {
              return formatDate(value, country, {
                locale: lng as LocaleCode,
                date: "long"
              });
            }
            return "";
          },
          escapeValue: false // react already saves from xss
        },
        debug: import.meta.env.DEV
      },
      noop
    );
  return i18n;
};
