export default data => {
  let finalStr = "";

  if (data.length === 1) {
    finalStr = data[0];
  } else if (data.length === 2) {
    finalStr = data[0] + " & " + data[1];
  } else {
    finalStr =
      data.slice(0, data.length - 1).join(", ") + " & " + data[data.length - 1];
  }

  return finalStr;
};
