import React, { Fragment, useState } from "react";
import { gql } from "@apollo/client";
import { withMutation, withQuery } from "@apollo/client/react/hoc";
import compose from "lodash/flowRight";
import { differenceInCalendarDays, parseISO } from "date-fns";

import toPluralizedString from "../utils/toPluralizedString";
import { Flex, Input, Typography, Box, Button } from "@anyfin/ui";

import { useTranslation } from "react-i18next";
import Section from "../components/Section";

const paymentAccountLabel = "paymentAccount";
const paymentReferenceLabel = "paymentReference";
const loanBalanceLabel = "currentDebt";

const TransferMissingInfoFormPage = ({
  match,
  data,
  provideTransferMissingPaymentInfo
}) => {
  const { transfer, loading } = data;
  const [hasSuccessfullySubmitted, setHasSuccessfullySubmitted] =
    useState(false);
  const [error, setError] = useState();
  const [loanBalance, setLoanBalance] = useState();
  const [paymentAccount, setPaymentAccount] = useState();
  const [paymentReference, setPaymentReference] = useState();
  const { t } = useTranslation("info");

  if (!transfer || loading) {
    return null;
  }

  const transferMissingInfoToken = match.params.transferMissingInfoToken;

  // the slice here is so that we clone the array instead of
  // referencing it
  const extractedMissingFields = transfer.metadata?.missingFields || [];
  const missingFields = extractedMissingFields.slice(0);

  // this checks if the transfer needs an updated loan amount
  if (
    differenceInCalendarDays(new Date(), parseISO(transfer.createdAt)) >= 14
  ) {
    missingFields.push(loanBalanceLabel);
  }

  const inputOnChange = (field, value) => {
    if (field === paymentAccountLabel) setPaymentAccount(value.trim());
    else if (field === paymentReferenceLabel) setPaymentReference(value.trim());
    else if (field === loanBalanceLabel) setLoanBalance(value);
  };

  const submitForm = () => {
    const mutationVariables = {
      transferMissingInfoToken
    };

    if (missingFields.includes(paymentAccountLabel))
      mutationVariables.paymentAccount = paymentAccount;

    if (missingFields.includes(paymentReferenceLabel))
      mutationVariables.paymentReference = paymentReference;

    if (missingFields.includes(loanBalanceLabel))
      mutationVariables.loanBalance = Number(loanBalance);

    provideTransferMissingPaymentInfo({
      variables: mutationVariables
    })
      .then(res => {
        const invalidFields = res.data.provideTransferMissingPaymentInfo;
        if (invalidFields.length === 0) setHasSuccessfullySubmitted(true);
        else setError(t("invalid") + " " + toPluralizedString(invalidFields));
      })
      .catch(e => setError(t("sending_information_error")));
  };

  return (
    <Section>
      {hasSuccessfullySubmitted ||
      missingFields.length === 0 ||
      transfer.status !== "paused" ? (
        <Flex flexDirection="column" alignItems="center">
          <Box width={["100%", "80%", "60%"]} style={{ textAlign: "center" }}>
            <Typography.H3 color="primary">{t("success_header")}</Typography.H3>
            <Typography paragraph style={{ fontSize: "1.3em" }}>
              {t("success_content")}
            </Typography>
            <Typography paragraph style={{ fontSize: "1.3em" }}>
              {t("success_ending_message")}
            </Typography>
          </Box>
        </Flex>
      ) : (
        <Flex flexDirection="column" alignItems="center">
          <Box
            width={["100%", "80%", "50%"]}
            marginTop="medium"
            marginBottom="medium"
          >
            <Typography.H3
              as="h2"
              style={{ textAlign: "center", whiteSpace: "pre-wrap" }}
            >
              {t("incomplete_header")}
            </Typography.H3>
          </Box>

          <Box
            width={["100%", "80%", "50%"]}
            marginTop="medium"
            marginBottom="medium"
          >
            <Typography paragraph>{t("incomplete_content_p1")}</Typography>
            <Typography paragraph>{t("incomplete_content_p2")}</Typography>
          </Box>
          <Box
            marginTop="medium"
            marginBottom="medium"
            width={["100%", "80%", "50%"]}
          >
            {missingFields.map(f => (
              <Fragment key={f}>
                <Input
                  name={f}
                  placeholder={t(f)}
                  onChange={e => inputOnChange(f, e.target.value)}
                  thin
                  fluid
                  style={{ marginBottom: "1em" }}
                  type={f === loanBalanceLabel ? "number" : "text"}
                />
              </Fragment>
            ))}
          </Box>
          <Box width={["100%", "80%", "50%"]}>
            <Button
              onClick={submitForm}
              fluid
              rounded
              elevated
              iconRight="ArrowRight"
            >
              {t("send_information")}
            </Button>
          </Box>
          {error && (
            <Box my="1em" width={["100%", "80%", "50%"]}>
              <Typography style={{ color: "red" }}>{error}</Typography>
            </Box>
          )}
        </Flex>
      )}
    </Section>
  );
};

const TransferQuery = withQuery(
  gql`
    query TransferQuery {
      me {
        id
        applications(status: "open") {
          id
          step
          transfer {
            id
            createdAt
            status
            amount
            metadata {
              missingInfoToken
              missingFields
            }
          }
        }
      }
    }
  `,
  {
    props: ({ data, ownProps }) => {
      const missingInfoToken = ownProps.match.params.transferMissingInfoToken;
      const transferApplication = data?.me?.applications.find(a => {
        return (
          a.step === "transfer" &&
          a.transfer?.metadata.missingInfoToken === missingInfoToken
        );
      });
      data.transfer = transferApplication?.transfer ?? null;
      return { data, ownProps };
    },
    options: ({ match }) => ({
      variables: {
        transferMissingInfoToken: match.params.transferMissingInfoToken
      }
    })
  }
);

const SubmitTransferMissingInfoFieldsMutation = withMutation(
  gql`
    mutation provideTransferMissingPaymentInfo(
      $transferMissingInfoToken: String!
      $paymentAccount: String
      $paymentReference: String
      $loanBalance: Float
    ) {
      provideTransferMissingPaymentInfo(
        transferMissingInfoToken: $transferMissingInfoToken
        paymentAccount: $paymentAccount
        paymentReference: $paymentReference
        loanBalance: $loanBalance
      )
    }
  `,
  {
    name: "provideTransferMissingPaymentInfo"
  }
);

export default compose(
  TransferQuery,
  SubmitTransferMissingInfoFieldsMutation
)(TransferMissingInfoFormPage);
