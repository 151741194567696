const needHelp = (e: any) => {
  if (e) e.preventDefault();
  const thisWindow = window as any;

  if (thisWindow.Intercom) {
    thisWindow.Intercom("show");
  }
};

export default needHelp;
