import React from "react";
import { Typography } from "@anyfin/ui";
import { useTranslation } from "react-i18next";
import Section from "../../components/Section";
import CopyContainer from "../../components/CopyContainer";

const RefundSuccessPage = ({ history }) => {
  const { t } = useTranslation("refund");
  const { name } = history.location.state;
  const firstName = name.split(" ")[0];
  return (
    <Section marginBottom={["xxxlarge", "xxxlarge", "6x"]}>
      <CopyContainer marginTop={2.5} marginBottom="medium">
        <Typography.H3>
          {t("success.thanks")} {firstName}!
        </Typography.H3>
      </CopyContainer>

      <CopyContainer marginTop={1.25} marginBottom="medium">
        <Typography>{t("success.message_standard")}</Typography>
      </CopyContainer>
    </Section>
  );
};

export default RefundSuccessPage;
