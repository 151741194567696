import { gql, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import AuthService from "../../../auth/AuthService";
import { Location } from "history";

const logToSentry = (
  customerId: string,
  message: string,
  type: Sentry.SeverityLevel
) => {
  Sentry.addBreadcrumb({
    category: "OFFER_AUTO_LOGIN",
    message: `${message} for user ${customerId}`,
    level: type
  });
};

const CollectLoginToken = gql`
  mutation CollectLoginToken($token: String!, $customerId: String!) {
    collectLoginToken(token: $token, customerId: $customerId) {
      tokenType
      accessToken
    }
  }
`;

enum ErrorType {
  BAD_USER_INPUT = "BAD_USER_INPUT",
  SESSION_EXPIRED = "SESSION_EXPIRED"
}

const getMessageTypeByCode = (code: ErrorType) => {
  switch (code) {
    case ErrorType.BAD_USER_INPUT:
      return "error";
    case ErrorType.SESSION_EXPIRED:
      return "warning";
    default:
      return "info";
  }
};

export const useAutomaticOfferLogin = (location: Location) => {
  const [collectLoginToken] = useMutation(CollectLoginToken, {
    context: { service: "gateway" }
  });

  const [customerId, setCustomerId] = useState<string>("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!location) return;
    let { search, hash } = location;

    let queryParams = new URLSearchParams(search);
    let token = queryParams.get("token");
    let customerIdParam = queryParams.get("customerId");

    // Temporary bug fix due to Sendgrid tracking issues.
    if (!token || !customerIdParam) {
      search = `${search}${hash}`.replace(
        "&&utm_source=sendgrid.com&utm_medium=email&utm_campaign=website#x3D;",
        "="
      );
      queryParams = new URLSearchParams(search);
      token = queryParams.get("token");
      customerIdParam = queryParams.get("customerId");
    }

    setCustomerId(customerIdParam || "");

    if (token && customerIdParam) {
      setLoading(true);

      collectLoginToken({ variables: { token, customerId: customerIdParam } })
        .then(({ data }) => {
          setLoading(false);
          const accessToken = data.collectLoginToken?.accessToken;
          if (accessToken) {
            logToSentry(
              customerIdParam || "",
              "Automatic login success",
              getMessageTypeByCode(data)
            );
            AuthService.login(accessToken);
          }
        })
        .catch(error => {
          setLoading(false);
          const code = error.graphQLErrors[0].extensions.code;
          logToSentry(
            customerIdParam || "",
            error.message,
            getMessageTypeByCode(code)
          );
        });
    }
  }, [location]);

  return { customerId, loading };
};
