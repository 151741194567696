import styled from "styled-components";
import { Button } from "@anyfin/ui";

const PillButton = styled(Button)`
  border-radius: 32px;
  padding: 0 18px 0 24px;
  height: 48px;
`;

export default PillButton;
