import * as Sentry from "@sentry/browser";
import { Breadcrumb } from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import { ApolloResponseMessages } from "./apollo";
import App from "./App";

const __DEV__ = import.meta.env.DEV;

const stripTokens = (event: Sentry.Event): Sentry.Event => {
  if (!event.request) return event;
  const strippedUrl = event.request?.url?.replace(/\/c\/\w+/, "/c/<token>");
  event.request.url = strippedUrl;
  return event;
};

// Breadcrumbs are logged from Apolloservice in more detail
const excludeGraphQLFetch = (breadCrumb: Breadcrumb) => {
  if (breadCrumb.type === "http" && breadCrumb.data?.url.endsWith("/graphql")) {
    return null;
  }
  return breadCrumb;
};

const getEnvironment = () => {
  if (__DEV__) return "local";
  return window.location.hostname.endsWith(".dev") ? "staging" : "production";
};

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    enabled: !__DEV__,
    environment: getEnvironment(),
    beforeBreadcrumb: excludeGraphQLFetch,
    beforeSend: stripTokens,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: __DEV__ ? 1.0 : 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    // https://github.com/anyfin/mobile-app/blob/main/src/util/logging.ts#L56-L67
    ignoreErrors: [
      "Invariant Violation: 20",
      "was mutated while being enumerated",
      "INVALID_LOGIN",
      "NOT_LOGGED_IN",
      "Error: Failed to connect to inapps.appsflyer.com/:::443",
      "Permission android.permission.CAMERA",
      "Network request failed",
      "com.appsflyer.sdk.event",
      "No dev key",
      ...Object.values(ApolloResponseMessages)
    ],
    integrations: [
      new Sentry.Replay({ maskAllText: true, blockAllMedia: true })
    ]
  });
}

// Patch onunhandledrejection, if module's cant be found just force reload the page,
// any other error can go to sentry though.
setTimeout(() => {
  if (window.onunhandledrejection) {
    const originalUnhandledRejectionHandler = window.onunhandledrejection;
    window.addEventListener("unhandledrejection", ev => {
      if (ev.reason instanceof Error) {
        if (
          (ev.reason.message as string).includes(
            "Failed to fetch dynamically imported module"
          ) ||
          (ev.reason.message as string).includes(
            "Importing a module script failed."
          )
        ) {
          // Because of a new deployment, files can't be found; reload page to try again
          window.location.reload();
          return;
        }
      }

      // Call the Sentry helper
      originalUnhandledRejectionHandler.call(window, ev);
    });
  }
}, 1000);

// eslint-disable-next-line react/jsx-filename-extension
ReactDOM.render(<App />, document.getElementById("root"));
