import styled, { css } from "styled-components";
import { media, Section } from "@anyfin/ui";

export default styled(Section).attrs({ color: "white" })`
  align-items: stretch;
  max-width: 100%;
  padding: 0;

  ${media.up.desktop(css`
    max-width: ${({ fullWidth }) => (fullWidth ? "1140px" : "565px")};
    padding: 0 1rem;
  `)};
`;
