import { useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";

import { withTranslation } from "react-i18next";

import { Typography, Notification, Box } from "@anyfin/ui";
import { AuthService } from "../auth";
import LoginOTP from "./Login/LoginOTP";
import LoginBankID from "./Login/LoginBankID";
import LoginScrive from "./Login/LoginScrive";
import Section from "../components/Section";
import CopyContainer from "../components/CopyContainer";
import { ScriveSource } from "../types/types";

const Login = props => {
  const { t, i18n, location } = props;
  const history = useHistory();
  const fromPath = location?.state?.from?.pathname ?? "";

  const logoutReasonKey = useMemo(() => {
    if (fromPath.toLowerCase().includes("offer")) {
      return "session_expired.offer";
    }
    if (location?.state?.reason) {
      return location.state.reason;
    }

    return null;
  }, [fromPath, location?.state]);

  useEffect(() => {
    sessionStorage.setItem("redirect", fromPath);
  }, [fromPath]);

  const onSuccess = token => {
    AuthService.login(token);
    history.replace("home");
  };

  return (
    <Section marginBottom={["xxxlarge", "xxxlarge", "6x"]}>
      <Helmet title="Login" />

      <Box>
        {logoutReasonKey ? (
          <Notification medium={false} iconLeft="Info">
            {t(logoutReasonKey)}
          </Notification>
        ) : null}
        <CopyContainer marginTop="xlarge" marginBottom="medium">
          <Typography.H5>{t("login_header")}</Typography.H5>
        </CopyContainer>
        <Box paddingLeft="medium" paddingRight="medium">
          {["fi-FI", "de-DE"].includes(i18n.language) && (
            <LoginOTP onSuccess={onSuccess} />
          )}
          {i18n.language === "nb-NO" && (
            <LoginScrive onSuccess={onSuccess} source={ScriveSource.mypages} />
          )}
          {i18n.language === "sv-SE" && <LoginBankID onSuccess={onSuccess} />}
        </Box>
      </Box>
    </Section>
  );
};

export default withTranslation("auth")(Login);
