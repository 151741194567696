import { gql, useQuery } from "@apollo/client";

const PAYMENT_PLAN_QUERY = gql`
  query PaymentPlanQuery(
    $count: Int!
    $applicationIds: [String!]
    $oneOffPaymentAmount: Float
    $monthlyPayment: Float
    $offersToken: String!
  ) {
    customer: customerByOffersToken(offersToken: $offersToken) {
      id
      paymentPlan: generatePaymentPlanForApplications(
        count: $count
        applicationIds: $applicationIds
        oneOffPaymentAmount: $oneOffPaymentAmount
        monthlyPayment: $monthlyPayment
      ) {
        date
        amount
        amortization
        balance
      }
    }
  }
`;

type PaymentPlanQueryResult = {
  customer?: {
    id: string;
    paymentPlan: {
      amount: number;
      balance: number;
      date: string;
    }[];
  };
};

const useGeneratePaymentPlan = ({
  oneOffPaymentAmount,
  applicationIds = [],
  count = 200,
  monthlyPayment,
  offersToken
}: {
  oneOffPaymentAmount?: number | null;
  applicationIds?: string[];
  count: number;
  monthlyPayment?: number | null;
  offersToken?: string;
}) => {
  const { data, loading } = useQuery<PaymentPlanQueryResult>(
    PAYMENT_PLAN_QUERY,
    {
      context: { service: "gateway" },
      variables: {
        oneOffPaymentAmount,
        applicationIds,
        count,
        monthlyPayment,
        offersToken
      }
    }
  );

  const { paymentPlan } = data?.customer || {};
  return {
    paymentPlan: paymentPlan || null,
    loading
  };
};

export default useGeneratePaymentPlan;
