import React from "react";
import PropTypes from "prop-types";
import { Typography, ListItem } from "@anyfin/ui";
import styled from "styled-components";

import ProviderAvatar from "./ProviderAvatar";
import { useTranslation } from "react-i18next";
import idx from "idx";

const TypographySecondary = styled(Typography)`
  color: ${({ theme }) => theme.colors.textLight.medium};
`;

const ProviderCard = ({
  provider,
  onClick,
  description,
  labelRight,
  iconRight,
  showAvatarBorder,
  color = "alpha.50",
  isDemo,
  ...rest
}) => {
  const { t } = useTranslation("offer");
  const lenderName = isDemo
    ? t("demo_lender")
    : provider.displayName || provider.name;

  const showBorder =
    !idx(provider, _ => _.brandAttributes.backgroundColor) ||
    idx(provider, _ => _.brandAttributes.backgroundColor).startsWith("#fff");

  return (
    <ListItem
      onClick={onClick}
      dataLeft={<ProviderAvatar provider={provider} showBorder={showBorder} />}
      dataRight={labelRight && labelRight}
      iconRight={{ icon: iconRight }}
      $color={color}
      floating={false}
      {...rest}
    >
      <Typography>{lenderName}</Typography>
      <TypographySecondary as="div">{description}</TypographySecondary>
    </ListItem>
  );
};

ProviderCard.propTypes = {
  provider: PropTypes.object,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelRight: PropTypes.any,
  iconRight: PropTypes.any,
  onClick: PropTypes.func,
  color: PropTypes.string,
  showAvatarBorder: PropTypes.bool
};

export default ProviderCard;
