import React, { useMemo } from "react";
import { Box, Typography } from "@anyfin/ui";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { formatMoney, formatPercent } from "@anyfin/number-formatter";
import { useCountry } from "@anyfin/number-formatter/components";

import Modal from "../../../components/Modal";
import CopyContainer from "../../../components/CopyContainer";
import PillButton from "../../../components/PillButton";
import { OfferType } from "../../../types/types";

const TypographySecondary = styled(Typography)`
  color: ${({ theme }) => theme.colors.textLight.medium};
`;

interface OfferTermsModalProps {
  visible: boolean;
  toggleModal: () => void;
  offers: Array<OfferType>;
  policy: any;
  demoProvider: string | undefined;
}
const OfferTermsModal = ({
  visible,
  toggleModal,
  offers,
  policy,
  demoProvider
}: OfferTermsModalProps) => {
  return (
    <Modal open={visible} toggleModal={toggleModal} title={""}>
      {offers.map(offer => (
        <OfferPaymentDetails
          key={offer.id}
          offer={offer}
          minPayment={policy.minPayment}
          demoProvider={demoProvider}
        />
      ))}
    </Modal>
  );
};

const StyledOfferPaymentDetails = styled.div`
  margin: 0 1.5rem;

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.alpha.hues?.[100]};
    padding-bottom: 1em;
    margin-bottom: 1em;
  }
`;

interface OfferPaymentDetailsProps {
  offer: OfferType;
  minPayment: number;
  demoProvider?: string;
}
const OfferPaymentDetails = ({
  offer,
  minPayment,
  demoProvider
}: OfferPaymentDetailsProps) => {
  const { t } = useTranslation("offer");
  const [country] = useCountry();

  const offerDetailText = useMemo(() => {
    if (!minPayment) return;

    return [
      t("offer_details.interest", {
        interesetRate: formatPercent(offer.interestRate, country, {
          decimals: true
        }),
        monthlyFee: formatMoney(offer.monthlyFee, country)
      }),
      offer.months >= 1 &&
        t("offer_details.monthly_pay", {
          cost: formatMoney(
            (offer.amount + offer.financingCost) / offer.months,
            country
          ),
          months: offer.months
        }),
      !offer.months &&
        t("offer_details.amortization", {
          amortizationRate: formatPercent(offer.amortizationRate, country, {
            decimals: true
          }),
          amount: formatMoney(minPayment, country)
        }),
      ""
    ]
      .filter(Boolean)
      .join(" ");
  }, [country, minPayment, offer, t]);

  return (
    <StyledOfferPaymentDetails>
      <Box>
        <CopyContainer padding={0} marginTop="large" marginBottom="xsmall">
          <Typography>{demoProvider || offer.lender.name}</Typography>
          <TypographySecondary>
            {formatMoney(offer.amount, country)}
          </TypographySecondary>
        </CopyContainer>

        <CopyContainer padding={0} marginBottom="medium">
          <Typography>{offerDetailText}</Typography>
        </CopyContainer>

        <CopyContainer padding={0}>
          <TypographySecondary>
            {t("offer_details.example", {
              offerAmount: formatMoney(offer.amount, country),
              pricingMonths: offer.pricingMonths,
              totalCost: formatMoney(
                offer.amount + offer.financingCost,
                country
              ),
              monthlyCost: formatMoney(
                (offer.amount + offer.financingCost) / offer.pricingMonths,
                country
              ),
              effectiveApr: formatPercent(offer.effectiveApr, country, {
                decimals: true
              })
            })}
          </TypographySecondary>
        </CopyContainer>
        {offer.contract ? (
          <CopyContainer
            padding={0}
            marginTop="medium"
            style={{ alignSelf: "flex-end" }}
          >
            <PillButton
              fontSize={1}
              fontWeightMedium
              color="alpha"
              onClick={() => {
                window.open(offer.contract.publicUrl, "_blank");
              }}
              iconRight="ArrowRight"
            >
              {t("offer_unsigned_contract")}
            </PillButton>
          </CopyContainer>
        ) : null}
      </Box>
    </StyledOfferPaymentDetails>
  );
};

export default OfferTermsModal;
