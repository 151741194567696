import { Redirect } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { RouteHelperProps, DefaultLayout, getCurrentLangPrefix } from "./App";
import { AuthService } from "./auth";

export const PrivateRoute = ({
  component: Component,
  layoutProps,
  ...rest
}: RouteHelperProps) => (
  <DefaultLayout
    {...rest}
    layoutProps={layoutProps}
    component={(matchProps: RouteComponentProps) =>
      AuthService.loggedIn() && Component ? (
        <Component {...matchProps} />
      ) : (
        <Redirect
          to={{
            pathname: `/${getCurrentLangPrefix()}/login`,
            state: { from: matchProps.location }
          }}
        />
      )
    }
  />
);
