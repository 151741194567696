import React from "react";
import { OfferType } from "../../../../types/types";
import { Box, Typography, theme } from "@anyfin/ui";
import styled from "styled-components";
import { useCountry } from "@anyfin/number-formatter/components";
import Section from "../../../../components/Section";
import RoundIcon from "../../../../components/RoundIcon";
import {
  OfferBalanceSplitModal,
  OfferAdjustedDurationModal,
  OfferAdjustmentModalProps,
  OttoModal
} from "./OfferAdjustmentModals";
import { useTranslation } from "react-i18next";
import CopyContainer from "../../../../components/CopyContainer";
import { COUNTRY_CODES } from "../../../../utils/countries";

const StyledSection = styled(Section)`
  padding: 0 0.5rem;
`;
interface OfferAdjustmentItem {
  key: string;
  title: string;
  description: string;
  Component: React.FC<OfferAdjustmentModalProps>;
  getOffers: (offers: Array<OfferType>) => Array<OfferType>;
  show: (offers: Array<OfferType>) => boolean;
  onClick?: () => void;
}

interface OfferAdjustmentsProps {
  offers: Array<OfferType>;
}
const OfferAdjustments = ({ offers }: OfferAdjustmentsProps) => {
  const { t } = useTranslation("offer");
  const [country] = useCountry();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modal, setModal] = React.useState<string>("");

  const ADJUSTMENTS: Record<COUNTRY_CODES, Array<OfferAdjustmentItem>> = {
    SE: [
      {
        key: "offer_duration",
        title: t("offer_adjustment_details.adjusted_duration_button_title"),
        description: t(
          "offer_adjustment_details.adjusted_duration_button_description"
        ),
        Component: OfferAdjustedDurationModal,
        onClick: () => {
          setModal("offer_duration");
          setModalOpen(true);
        },
        getOffers: (offers: Array<OfferType>) => {
          if (!offers) return [];
          return offers?.filter(
            offer =>
              offer.originalMonths &&
              offer.originalMonths !== offer.months &&
              offer.monthlyPayment > offer.originalMonthlyPayment
          );
        },
        show: offers => {
          return (
            offers?.filter(
              offer =>
                offer.originalMonths &&
                offer.originalMonths !== offer.months &&
                offer.monthlyPayment > offer.originalMonthlyPayment
            ).length > 0
          );
        }
      },
      {
        key: "offer_balance_split",
        title: t("offer_adjustment_details.balance_split_button_title"),
        description: t(
          "offer_adjustment_details.balance_split_button_description"
        ),
        Component: OfferBalanceSplitModal,
        onClick: () => {
          setModal("offer_balance_split");
          setModalOpen(true);
        },
        getOffers: (offers: Array<OfferType>) => {
          if (!offers) return [];
          return offers?.filter(
            offer =>
              offer.originalAmount &&
              offer.originalAmount !== offer.amount &&
              offer.originalAmount > offer.amount
          );
        },
        show: offers =>
          offers?.filter(
            offer =>
              offer.originalAmount &&
              offer.originalAmount !== offer.amount &&
              offer.originalAmount > offer.amount
          ).length > 0
      }
    ],
    FI: [],
    DE: [
      {
        key: "otto",
        title: t("offer_adjustment_details.otto_button_title"),
        description: t("offer_adjustment_details.otto_button_description"),
        Component: OttoModal,
        onClick: () => {
          setModal("otto");
          setModalOpen(true);
        },
        getOffers: (offers: Array<OfferType>) => {
          if (!offers) return [];
          return offers?.filter(offer => offer.lender.name === "Otto");
        },
        show: offers =>
          offers?.filter(offer => offer.lender.name === "Otto").length > 0
      }
    ],
    NO: []
  };

  const showSection = ADJUSTMENTS[country as COUNTRY_CODES].some(
    (item: OfferAdjustmentItem) => item.show(offers)
  );

  if (!showSection) return null;

  return (
    <>
      <StyledSection>
        <Box marginBottom={0.5}>
          <CopyContainer>
            <Typography size={1} medium>
              {t("important_information")}
            </Typography>
          </CopyContainer>
        </Box>
        {ADJUSTMENTS[country as COUNTRY_CODES].map(
          (item: OfferAdjustmentItem) => {
            const ItemComponent = item.Component;
            if (item.show(offers)) {
              const itemOffers = item.getOffers?.(offers);
              return (
                <>
                  <OfferAdjustmentItemButton
                    title={item.title}
                    description={item.description}
                    onClick={item.onClick}
                  />
                  <ItemComponent
                    isOpen={modalOpen && modal === item.key}
                    toggleModal={() => setModalOpen(false)}
                    offers={itemOffers}
                  ></ItemComponent>
                </>
              );
            }
          }
        )}
      </StyledSection>
    </>
  );
};

const OfferAdjustmentBox = styled(Box)`
  border: 1px solid #cfc8c0;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;

interface OfferAdjustmentItemProps {
  title: string;
  description: string;
  onClick?: () => void;
}
const OfferAdjustmentItemButton = ({
  title,
  description,
  onClick
}: OfferAdjustmentItemProps) => {
  return (
    <OfferAdjustmentBox padding={"medium"} onClick={onClick}>
      <RoundIcon
        icon="Info"
        iconSize={1.25}
        size={2.5}
        background={theme.colors.alpha.hues?.[100] || ""}
      ></RoundIcon>
      <div className="" style={{ marginLeft: "0.67rem", flexShrink: 1 }}>
        <Typography size={1} medium>
          {title}
        </Typography>
        <Typography size={1} style={{ fontSize: "1rem", lineHeight: 1.25 }}>
          {description}
        </Typography>
      </div>
      <RoundIcon
        icon="ChevronRight"
        iconSize={1.25}
        size={2.5}
        background="#FFFFFF00"
      ></RoundIcon>
    </OfferAdjustmentBox>
  );
};

export default OfferAdjustments;
