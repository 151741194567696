/* eslint-disable react/jsx-filename-extension */
import { ApolloProvider } from "@apollo/client";
import React, { useEffect, useState, useMemo, Suspense } from "react";

import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import type { RouteComponentProps } from "react-router";
import { theme } from "@anyfin/ui";
import apolloClient from "./apollo";

import { AuthService, provideAuth } from "./auth";

import Layout from "./layout/Layout";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage/HomePage";
import OfferSuccessPage from "./pages/OfferSuccessPage";
import RefundSuccessPage from "./pages/RefundPage/RefundSuccessPage";
import {
  setup,
  localeFromUrl,
  countryFromUrl,
  SUPPORTED_LANGUAGES,
  LocaleCodeURL as URLCode
} from "./utils/i18n";
import i18n from "i18next";
import { homeUrl } from "./utils/routes";
import {
  CountryProvider,
  useCountry
} from "@anyfin/number-formatter/components";
import polyfillIntl from "@anyfin/number-formatter/intl-polyfill-dynamic";
import { ThemeProvider } from "styled-components";
import TransferMissingInfoFormPage from "./pages/TransferMissingInfoFormPage";
import { initializeSnippet, usePageViews } from "./utils/segment";
import { I18nextProvider, useTranslation } from "react-i18next";
import UniversalOfferPage from "./pages/CombinedOfferPage/UniversalOfferpage";
import { PrivateRoute } from "./PrivateRoute";

const OfferPage = React.lazy(
  () => import("./pages/CombinedOfferPage/OfferPage")
);

const InfoCertSigning = React.lazy(
  () =>
    import(
      "./pages/CombinedOfferPage/OfferForm/OfferSignInfoCert/InfoCertSigning"
    )
);

const RefundPage = React.lazy(() => import("./pages/RefundPage/RefundPage"));

const W: any = window;
initializeSnippet();
setup();
const auth = AuthService; // instance

export const getCurrentLangPrefix = (): URLCode => {
  // Figure out active language, redirect to appropriate login page
  const code = Object.entries(SUPPORTED_LANGUAGES).find(
    ([, lng]) => lng.code === i18n.language
  )?.[0] as URLCode;
  return code || "sv_SE";
};

export interface RouteHelperProps extends React.ComponentProps<typeof Route> {
  layoutProps: {
    basicHeader?: boolean;
    noHeader?: boolean;
    noFooter?: boolean;
    countrySelect?: boolean;
  };
}
export const DefaultLayout = ({
  component: Component,
  layoutProps,
  ...rest
}: RouteHelperProps) => (
  <Route
    {...rest}
    render={matchProps => (
      <Layout {...layoutProps}>
        {Component ? <Component {...matchProps} /> : null}
      </Layout>
    )}
  />
);

export const logout = (): void => {
  auth.logout();
  apolloClient.cache.reset();
  if (W.analytics) {
    W.analytics.reset();
  }
  if (W.Intercom) {
    W.Intercom("shutdown");
  }
};

const LogoutAndRedirect = () => {
  const [redirect, setRedirect] = useState<null | true>(null);

  const code = getCurrentLangPrefix();
  logout();
  if (redirect) {
    return <Redirect to={`/${code}/login`} />;
  }
  setRedirect(true);
  return <span>....</span>;
};

const AuthFromToken = ({ match }: RouteComponentProps<{ token: string }>) => {
  const {
    params: { token }
  } = match;
  const [redirect, setRedirect] = useState<null | string>(null);
  const location = useLocation();
  const customRedirectPath = location.search.match(/redirect=(.+)/)?.[1];
  useEffect(() => {
    logout();
    // Replace dots on auth token, remove after this is merged:
    // https://github.com/vitejs/vite/pull/2634
    const fixToken = token.replaceAll("DOT", ".");

    // eslint-disable-next-line promise/catch-or-return
    auth
      .login(fixToken, { isAdmin: true })
      .then(() => {
        setRedirect(
          customRedirectPath ? `../${customRedirectPath}` : homeUrl(match)
        );
        return true;
      })
      .catch(() => {
        setRedirect("/");
      });
  }, []);
  if (redirect) {
    return <Redirect to={redirect} />;
  } else {
    return null;
  }
};

const CountryRoutes = ({ match: { url } }: RouteComponentProps) => {
  const [, setCountry] = useCountry();
  const [, i18n] = useTranslation();

  const [country, locale] = useMemo(() => {
    const country = countryFromUrl(url);
    const locale = localeFromUrl(url);
    return [country, locale];
  }, [url]);

  // Centralized place for locale settings
  useEffect(() => {
    if (!country) return;
    // eslint-disable-next-line promise/catch-or-return
    Promise.all([polyfillIntl(locale), i18n.changeLanguage(locale)]).then(
      () => {
        document.querySelector("html")?.setAttribute("lang", locale);
        return setCountry(country);
      }
    );
  }, [setCountry, locale, country]);

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <Switch>
          <PrivateRoute
            path={url + "/home"}
            component={HomePage}
            layoutProps={{ basicHeader: true }}
          />
          <DefaultLayout
            layoutProps={{
              countrySelect: true
            }}
            path={url + "/login"}
            component={LoginPage}
          />
          <Route path={url + "/logout"} component={LogoutAndRedirect} />
          <Route path={url + "/auth/:token"} component={AuthFromToken} />

          {/* Disable friend referral in SE due to legal reasons */}
          <Redirect
            from={`/${URLCode.sv_SE}/terms/referral`}
            to={`/${URLCode.sv_SE}`}
          />
          <PrivateRoute
            layoutProps={{ noHeader: true }}
            path={url + "/c/success/:offers?"}
            component={OfferSuccessPage}
          />
          <PrivateRoute
            layoutProps={{ noHeader: true }}
            path={url + "/success/:offers?"}
            component={OfferSuccessPage}
          />
          <PrivateRoute
            layoutProps={{
              noHeader: true,
              noFooter: true
            }}
            path={url + "/t/:transferMissingInfoToken"}
            component={TransferMissingInfoFormPage}
          />

          <DefaultLayout
            layoutProps={{
              noHeader: true,
              noFooter: true
            }}
            path={"/:locale/c/:offersToken/signing"}
            component={InfoCertSigning}
          />
          <PrivateRoute
            layoutProps={{
              noHeader: true,
              noFooter: true
            }}
            path={["/:locale/offer/signing", "/:locale/Offer/signing"]}
            component={InfoCertSigning}
          />

          {/* @deprecated as part of phasing out offer tokens */}
          <DefaultLayout
            layoutProps={{
              noHeader: true,
              noFooter: true
            }}
            path={"/:locale/c/:offersToken/(preview)?"}
            component={OfferPage}
          />
          {/**
           *  Universal link to offer page, in case of broken/non-existing user session
           *  this route deals  with re-authentication */}
          <DefaultLayout
            layoutProps={{
              noHeader: true,
              noFooter: true
            }}
            path={["/:locale/offer", "/:locale/Offer"]}
            component={UniversalOfferPage}
          />

          <PrivateRoute
            layoutProps={{ basicHeader: true }}
            exact
            path={url + "/overpayment_refund_form/success"}
            component={RefundSuccessPage}
          />

          <PrivateRoute
            layoutProps={{ basicHeader: true }}
            exact
            path={url + "/overpayment_refund_form"}
            component={RefundPage}
          />

          <Redirect from="/" to="home" />
        </Switch>
      </ThemeProvider>
    </ApolloProvider>
  );
};

/**
 * Supported languages, otherwise redirect to default lang login page?
 */
const RewriteAppLocale = () => {
  usePageViews();
  return (
    <Switch>
      <Route path="/health" render={() => "OK"}></Route>

      {Object.entries(SUPPORTED_LANGUAGES)
        .filter(([, val]) => val.country)
        .map(([langCode]) => (
          <Route
            key={langCode}
            path={`/${langCode}`}
            component={CountryRoutes}
          />
        ))}

      <Route
        path="/c/:offersToken/(preview)?"
        // @ts-ignore
        component={({ match: { params } }) => (
          <Redirect
            to={`/${URLCode.sv_SE}/c/${params.offersToken}${
              params[0] === "preview" ? "/preview" : ""
            }`}
          />
        )}
      />
      {/* Redirect Auth from token, remove if country prefix is added in admin */}
      <Route
        path={"/auth/:token"}
        component={({
          match: { params }
        }: RouteComponentProps<{ token: string }>) => (
          <Redirect to={`/${URLCode.sv_SE}/auth/` + params.token} />
        )}
      />
      {/* Fallback lang */}
      <Route component={() => <Redirect to={`/${URLCode.sv_SE}/login`} />} />
    </Switch>
  );
};

const App = () => (
  <Suspense fallback={"..."}>
    <I18nextProvider i18n={i18n}>
      <CountryProvider>
        <BrowserRouter>
          <RewriteAppLocale />
        </BrowserRouter>
      </CountryProvider>
    </I18nextProvider>
  </Suspense>
);

export default provideAuth(auth)(App);
