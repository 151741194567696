import { gql, useQuery } from "@apollo/client";
import { withQuery } from "@apollo/client/react/hoc";

const POLICY_QUERY = gql`
  query PolicyQuery($countryCode: String!) {
    policy: countryPolicyConfigByCountry(countryCode: $countryCode) {
      minPayment
      wasReferredBonus
      wasReferredInterestFreeMonths
      lateFee
    }
  }
`;

/**
 * Helper to add policy information for countrycode to a component
 * Usually depends on a countryCode from another query
 * Therefore exposes 'skip' and 'options'
 */
const PolicyQuery = ({ skip, options }) =>
  withQuery(POLICY_QUERY, { skip, options });

const withPolicy = ({ skip, options }) => {
  return PolicyQuery({ skip, options });
};

export const usePolicy = ({ skip, options, variables }) =>
  useQuery(POLICY_QUERY, { skip, options, variables });

export default withPolicy;
