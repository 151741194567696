import { Box, Typography } from "@anyfin/ui";
import React from "react";
import styled from "styled-components";

interface RowProps {
  isHeader?: boolean;
  hideRowBorder?: boolean;
}

const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 1rem;

  &:not(:last-of-type) {
    border-bottom: 1px solid
      ${props => (props.hideRowBorder ? "transparent" : "#CFC8C0")};
    padding-bottom: ${props => (props.isHeader ? ".5rem" : "1rem")};
  }

  & > * {
    flex: 1 1 0;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

interface StyledTypographyProps {
  lineThrough?: boolean;
  emphasize?: boolean;
  deemphasize?: boolean;
}
const StyledTypography = styled(Typography)<StyledTypographyProps>`
  color: ${props =>
    props.emphasize
      ? "#199F72"
      : props.deemphasize
      ? props.theme.colors.textLight.medium
      : "inherit"};
  text-decoration: ${props => (props.lineThrough ? "line-through" : "initial")};
`;

interface TableCompareValue {
  value: string;
  lineThrough?: boolean;
  emphasize?: boolean;
  deemphasize?: boolean;
}
export interface TableCompareItem {
  title: string;
  values: Array<TableCompareValue>;
  isHeader?: boolean;
  titleAfter?: string;
}
interface TableCompareProps {
  items: Array<TableCompareItem>;
  headerSize?: number;
  contentSize?: number;
  hideRowBorder?: boolean;
}

const TableCompare = ({
  items,
  headerSize,
  contentSize,
  hideRowBorder
}: TableCompareProps) => {
  return (
    <Box margin={0} padding={0}>
      {items.map((item, i) => (
        <Row
          key={i}
          isHeader={item.isHeader}
          hideRowBorder={item.isHeader || hideRowBorder}
        >
          {item.values.map(
            ({ value, lineThrough, emphasize, deemphasize }, j) => {
              if (item.isHeader)
                return (
                  <StyledTypography size={headerSize ?? 1} medium>
                    {value}
                  </StyledTypography>
                );

              return (
                <Column key={j}>
                  <StyledTypography size={0.75} deemphasize>
                    {j === 1 && item.titleAfter ? item.titleAfter : item.title}
                  </StyledTypography>
                  <StyledTypography
                    size={contentSize ?? 1.5}
                    emphasize={emphasize}
                    deemphasize={deemphasize}
                    lineThrough={lineThrough}
                  >
                    {value}
                  </StyledTypography>
                </Column>
              );
            }
          )}
        </Row>
      ))}
    </Box>
  );
};

export default TableCompare;
