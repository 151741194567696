import * as Sentry from "@sentry/browser";
import { gql } from "@apollo/client";
import jwtDecode from "jwt-decode";
import pick from "lodash/pick";

import apolloClient from "../apollo";
import { addMinutes } from "date-fns";
import { LocalStorageService } from "../services/LocalStorageService";

const TOKEN_NAME = "auth_token";
const IS_ADMIN = "is_admin";
const OTP_LOGIN_ATTEMPT = "otp_login_attempt";

const USER_INIT = gql`
  query userInit {
    me {
      id
      name
      firstName
      identities {
        id
        type
        value
        verified
      }
      status
      isDemo
      isOverdue
      countryCode
      currencyCode
      applicationsCount
      hasActiveLoans
      email
    }
  }
`;

const checkAndFilterToken = token => {
  if (!token) {
    throw new Error("Token Missing!");
  }
  const decoded = jwtDecode(token);
  const filtered = pick(decoded, ["id", "exp", "ic_hash"]);
  if (new Date().getTime() > filtered.exp * 1000) {
    // Token expired, don't bother
    throw new Error("Token expired");
  }
  return filtered;
};

class AuthService {
  user = null;
  loginCallback = null; // (loggedIn: boolean) => void;
  localStorageService = new LocalStorageService();

  init = async ({ onChange }) => {
    this.loginCallback = onChange || null;
    const token = this.getToken();
    const filtered = checkAndFilterToken(token);
    this.user = await this.enhanceUser(filtered);
    return this.user;
  };

  otpLoginAttempt = (sessionId, channelHint) => {
    this.localStorageService.setItem(
      OTP_LOGIN_ATTEMPT,
      { sessionId, channelHint },
      addMinutes(new Date(), 5)
    );
  };

  clearOtpLoginAttempt = () =>
    this.localStorageService.removeItem(OTP_LOGIN_ATTEMPT);

  get lastOtpLoginAttempt() {
    return this.localStorageService.getItem(OTP_LOGIN_ATTEMPT);
  }

  login = async (token, options = {}) => {
    const { isAdmin = false } = options;
    localStorage.setItem(TOKEN_NAME, token);
    localStorage.setItem(IS_ADMIN, isAdmin ? "true" : "false");
    const filtered = checkAndFilterToken(token);
    this.user = await this.enhanceUser(filtered);
    Sentry.setUser({ id: this.user.id });
    if (this.loginCallback) {
      this.loginCallback(true);
    }
    return this.user;
  };

  enhanceUser = async user => {
    try {
      const { data } = await apolloClient.query({
        query: USER_INIT,
        fetchPolicy: "network-only"
      });
      return { ...user, ...data.me };
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  getToken() {
    return localStorage.getItem(TOKEN_NAME);
  }

  clearTokens() {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(IS_ADMIN);
  }

  get activeUser() {
    return this.user;
  }

  isAdmin() {
    return localStorage.getItem(IS_ADMIN) === "true";
  }

  loggedIn() {
    return !!this.getToken();
  }

  logout() {
    this.clearTokens();
    this.user = null;
    if (this.loginCallback) {
      this.loginCallback(false);
    }
  }
}

const auth = new AuthService();
export default auth;
