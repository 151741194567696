export const APP_STORE_TRACKING_URL = "https://anyfin.onelink.me/qbaL/5ad8b48a";
export const GERMANY_OFFER_SIGN_ID = "b618d87f";
export const isAppInCompatibleDevice = () => {
  return !/Android|iPhone|iPad/i.test(navigator.userAgent);
};

const ONE_LINK_TEMPLATE_URL = "https://anyfin.onelink.me/qbaL/";

/**
 * Go to App store directly, dealing with clicks on "download app" CTA
 */
export const downloadApp = (LINKID = "") => {
  setTimeout(() => {
    window.location.href = ONE_LINK_TEMPLATE_URL + LINKID;
  }, 300);
};
