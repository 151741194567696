import { useLocation } from "react-router";
import { useEffect } from "react";
import set from "lodash/set";
import get from "lodash/get";
import snippet from "@segment/snippet";

export const initializeSnippet = () => {
  const jsSnippet = snippet.max({ apiKey: import.meta.env.VITE_SEGMENT_ID });
  const script = document.createElement("script");
  script.innerHTML = jsSnippet;
  document.body.appendChild(script);
  // Load local script is synchronous, so window.analytics is available from here
  addMiddleWare();
};

/**
 * From a given nested object path, check if it contains the offerToken,
 * and replace it with placeholder value.
 */
const sanitizePayloadAt = (obj, path) => {
  const val = get(obj, path);
  // object path is not found, leave as-is.
  if (!val) return;
  const strippedPath = val.replace(/\/c\/\w+/, "/c/<token>");
  set(obj, path, strippedPath);
};

const addMiddleWare = () => {
  window.analytics.addSourceMiddleware(({ payload, next }) => {
    const traits = window.analytics.user().traits();
    // Add traits
    payload.obj.context.traits = {
      ...traits,
      ...payload.obj.context.traits
    };

    sanitizePayloadAt(payload, "obj.context.page.path");
    sanitizePayloadAt(payload, "obj.context.page.url");
    sanitizePayloadAt(payload, "obj.properties.url");
    sanitizePayloadAt(payload, "obj.properties.path");

    next(payload);
  });
};

export function trackEvent(eventName, variables) {
  if (window.analytics) {
    window.analytics.track(eventName, variables);
  }
}

export function identify(user) {
  if (window.analytics) {
    window.analytics.identify(
      user?.id.toString(),
      {
        name: user.name,
        isDemo: user.isDemo,
        status: user.status,
        email: user.email || null,
        address: {
          country: user.countryCode
        }
      },
      {
        integrations: {
          Intercom: user.ic_hash
            ? {
                user_hash: user.ic_hash
              }
            : false
        }
      }
    );
  }
}

export function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    trackPage();
  }, [location]);
}

export const trackPage = (category, page) => {
  if (typeof window !== "undefined" && window.analytics) {
    window.analytics.page(category, page);
    // Only for FB, restricted to 1 destination
    window.analytics.track(
      "VirtualPageView",
      {},
      { integrations: { All: false, "Facebook Pixel": true } }
    );
  }
};
