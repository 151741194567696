import React from "react";
import styled from "styled-components";
import { Box, Icon } from "@anyfin/ui";
import { IconStroked } from "@anyfin/ui/es/types/components/icons/stroked";
import { IconFilled } from "@anyfin/ui/es/types/components/icons/filled";

interface IconWrapperProps {
  background: string;
  size: number;
}
const IconWrapper = styled(Box)<IconWrapperProps>`
  background-color: ${({ background }) => background};
  border-radius: 50%;

  display: flex;
  height: ${({ size }) => size}rem;
  flex: 0 0 ${({ size }) => size}rem;
  justify-content: center;
  align-items: center;
`;

interface RoundIconProps {
  background: string;
  size: number;
  icon: IconStroked | IconFilled;
  iconSize: number;
}
const RoundIcon = ({ background, size, icon, iconSize }: RoundIconProps) => {
  return (
    <IconWrapper background={background} size={size}>
      <Icon icon={icon} size={iconSize}></Icon>
    </IconWrapper>
  );
};

export default RoundIcon;
