/* eslint-disable react/jsx-filename-extension */
import { FetchResult, gql, useMutation } from "@apollo/client";
import { GraphQLError } from "graphql";
import { createContext, PropsWithChildren, useRef, useState } from "react";
import { useHistory } from "react-router";

import { useCountry } from "@anyfin/number-formatter/components";
import { COUNTRY_CODES } from "../../../utils/countries";
import { UserCancelError } from "../../../utils/errors";

export interface SignConfig {
  provider: string;
  needsPolling?: boolean;
  pollingInterval?: number;
  needsPreSign?: (startOfferSign: () => Promise<any>) => boolean;
  startSign: (data: any) => Promise<any> | void;
  collectSign: (data: any) => Promise<any>;
  beforeCollect?: (data: FetchResult["data"]) => {
    ref: string;
    token?: string;
  };
  collectHandler?: (data: any, resolver: (value: any) => void) => void;
}

const START_SCRIVE = gql`
  mutation startOfferSignScrive($offerIds: [ID!]!, $offersToken: String!) {
    startOfferSignScrive(offerIds: $offerIds, offersToken: $offersToken) {
      transactionId
      authUrl
    }
  }
`;

const START_BANKID = gql`
  mutation startOfferSignBankID($offerIds: [ID!]!, $offersToken: String!) {
    startOfferSignBankID(
      offerIds: $offerIds
      offersToken: $offersToken
      preventRemoteStart: true
    ) {
      orderRef
      autoStartToken
      qrContent
    }
  }
`;

type CollectMutation = {
  collect: {
    status: string;
    accessToken: string;
    qrContent?: string;
  };
};

const COLLECT_SCRIVE = gql`
  mutation collectOfferSignScrive($ref: String!) {
    collect: collectOfferSignScrive(transactionId: $ref) {
      status
      accessToken
    }
  }
`;

const COLLECT_BANKID = gql`
  mutation collectOfferSignBankID($ref: String!) {
    collect: collectOfferSignBankID(orderRef: $ref) {
      status
      accessToken
      qrContent
    }
  }
`;

type OfferSignStatus = "NEW" | "INIT" | "PROCESSING" | "FAILED" | "COMPLETED";

type SigningProvider = "BankID" | "Scrive" | "InfoCert" | "Unsupported";

// eslint-disable-next-line no-undef
const defaultProviderForCountry: Record<COUNTRY_CODES, SigningProvider> = {
  SE: "BankID",
  DE: "InfoCert",
  FI: "Scrive",
  NO: "Scrive"
};

interface OfferSignContextInterface {
  // start the signing process
  startSign: (offerIds: string[], offersToken: string) => Promise<any>;
  // Cancel the flow, either just navigate away, or clean up polling etc
  cancel: () => void;
  // to set the current provider
  setProvider: (p: SigningProvider) => void;
  // to set the status, in case we want to handle this elsewhere
  setStatus: (s: OfferSignStatus) => void;
  // contains the status of the process can be used by consuming component to navigate or refresh
  status: OfferSignStatus;
  error?: Error | GraphQLError;
  provider: SigningProvider;
  signOfferData: {
    authUrl?: string;
    autoStartToken?: string;
    qrContent?: string;
  };
}

const initialContext: OfferSignContextInterface = {
  // functions will be overwritten in provider
  startSign: () => Promise.resolve(),
  cancel: () => undefined,
  setProvider: () => undefined,
  setStatus: () => undefined,
  status: "NEW",
  provider: "BankID", // type needs a default
  signOfferData: {}
};

const OfferSignContext =
  createContext<OfferSignContextInterface>(initialContext);

interface OfferSignContextProviderProps {
  defaultSigningProvider?: SigningProvider;
}

const OfferSignContextProvider = ({
  children,
  defaultSigningProvider
}: PropsWithChildren<OfferSignContextProviderProps>) => {
  const [country] = useCountry();
  const history = useHistory();

  if (country === "GB") {
    throw new Error("unsupported country");
  }
  const [provider, setProvider] = useState<SigningProvider>(
    // @ts-expect-error something wrong with TS parsing in CRA
    defaultSigningProvider || defaultProviderForCountry[country]
  );
  const [status, setStatus] = useState(initialContext.status);
  const [error, setError] = useState<Error | GraphQLError | undefined>();
  const cancelRef = useRef<boolean>(false);
  const [signOfferData, setSignOfferData] = useState({});

  const [startOfferSignScrive] = useMutation(START_SCRIVE);
  const [collectOfferSignScrive] = useMutation<CollectMutation>(COLLECT_SCRIVE);
  const [startOfferSignBankId] = useMutation(START_BANKID);
  const [collectOfferSignBankID] = useMutation<CollectMutation>(COLLECT_BANKID);

  // eslint-disable-next-line no-undef
  const providerConfig: Record<SigningProvider, SignConfig> = {
    BankID: {
      provider: "BankId",
      startSign: startOfferSignBankId,
      collectSign: collectOfferSignBankID,
      needsPolling: true,
      pollingInterval: 1000,
      beforeCollect: data => {
        const { autoStartToken, orderRef, qrContent } =
          data?.startOfferSignBankID;
        setSignOfferData({ qrContent, orderRef, autoStartToken });
        return { token: autoStartToken, ref: orderRef };
      },
      collectHandler: (data, resolve) => {
        if (data?.collect) {
          const { status, qrContent, accessToken } = data.collect;
          if (status && status !== "pending") {
            resolve(accessToken);
          } else {
            setSignOfferData({
              autoStartToken: data?.startSignData?.token,
              qrContent
            });
          }
        }
      }
    },
    Scrive: {
      provider: "Scrive",
      startSign: startOfferSignScrive,
      collectSign: collectOfferSignScrive,
      needsPolling: true,
      beforeCollect: data => {
        const { transactionId, authUrl } = data?.startOfferSignScrive;
        setSignOfferData({ authUrl });
        return { ref: transactionId };
      }
    },
    // InfoCert signing type is in our control, we have more interaction with api/gateway inside the InfoCertStack
    InfoCert: {
      provider: "InfoCert",
      startSign: () => {
        history.push({
          pathname: history.location.pathname + "/signing"
        });
      },
      collectSign: () => Promise.resolve(true)
      // This opens the infoCert stack
      // navigation.navigate("OfferSignInfoCert", { offerIds, offersToken })
    },

    // Point to the app for signing.
    Unsupported: {
      provider: "Unsupported",
      startSign: () => Promise.resolve(true),
      collectSign: () => Promise.resolve(true)
    }
  };

  // Fetch the config for the current active or default SigningProvider
  const config = providerConfig[provider];
  // The exported startSign function
  const startSign = async (
    offerIds: string[],
    offersToken: string
  ): Promise<unknown> => {
    // make sure the cancelRef is set to false, in case of restarting
    cancelRef.current = false;
    // status should be INIT
    setStatus("INIT");
    // reset error
    setError(undefined);
    try {
      // Start the actual signing session for most third party providers, or any custom business logic
      const response = await config.startSign({
        variables: { offerIds, offersToken }
      });
      // Offer Sign status is processing
      setStatus("PROCESSING");
      // When the provider is configured to resolve by polling, start the polling!
      if (config.needsPolling && config.beforeCollect && config.collectSign) {
        // when beforeCollect is a thing call it, we need thr ref
        const startSignData = config.beforeCollect(response?.data);
        // start a new promise in which we will recursively poll for a success result
        const accessToken = await new Promise((resolve, reject) => {
          // define the poll function
          const poll = async (): Promise<void> => {
            // if the cancelRef is set to
            if (cancelRef.current) {
              return reject(new UserCancelError("User cancelled"));
            }
            // call the collect query
            config
              .collectSign({ variables: { ref: startSignData.ref } })
              .then(({ data }) => {
                // if there is a custom handler, we pass the result to that
                if (config.collectHandler) {
                  config.collectHandler({ ...data, startSignData }, resolve);
                }
                // if an accessToken is returned we can mark the process as completed
                else if (data?.collect?.accessToken) {
                  // resolve
                  return resolve(data.collect.accessToken);
                }
                // retry
                return setTimeout(poll, config.pollingInterval || 2000);
              })
              .catch(reject);
          };
          // start polling
          poll();
        });
        // If above call is finished successfully
        setStatus("COMPLETED");
        return accessToken;
      }
    } catch (e) {
      // On cancel, reset state
      if (e instanceof UserCancelError) {
        setStatus("NEW");
      } else if (e instanceof Error) {
        console.error(e);
        // Any failure will cancel the signing flow write the error to state
        setError(e);
        // mark as failed!
        setStatus("FAILED");
      }
    }
  };
  const cancel = (): void => {
    cancelRef.current = true;
  };

  return (
    <OfferSignContext.Provider
      value={{
        status,
        startSign,
        setStatus,
        cancel,
        setProvider,
        error,
        provider,
        signOfferData
      }}
    >
      {children}
    </OfferSignContext.Provider>
  );
};

export { OfferSignContext, OfferSignContextProvider };
export type { OfferSignStatus, SigningProvider };
