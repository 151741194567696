import React from "react";
import { OfferType } from "../../../../types/types";
import { useTranslation } from "react-i18next";
import { Box, Icon, Notification, Typography } from "@anyfin/ui";
import ProviderAvatar from "../../../../components/ProviderAvatar";
import { TypographySecondary } from "../../components/Typography";
import {
  CountryCode,
  formatMoney,
  formatPercent
} from "@anyfin/number-formatter/dist/cjs/index";

interface SplitBalanceOffer extends OfferType {
  originalAmount: number;
}
const isSplitBalanceOffer = (offer: OfferType): offer is SplitBalanceOffer =>
  Boolean(offer?.originalAmount && offer.originalAmount !== offer.amount);

const InvoiceOfferList = ({
  offers,
  country
}: {
  offers: OfferType[];
  country: CountryCode;
}) => {
  const { t } = useTranslation("offer");
  return (
    <>
      {offers?.length > 0 ? (
        <>
          <Box marginLeft={1} marginTop={2}>
            <Typography.H7>{t`offer_loan_detail.invoices_title`}</Typography.H7>
          </Box>

          {offers.map(offer => (
            <Box
              marginLeft={1}
              marginTop={1}
              marginBottom={2}
              direction="row"
              align="center"
              key={offer.id}
            >
              <ProviderAvatar
                showBorder={true}
                provider={offer?.lender}
                demoProvider={undefined}
              />
              <Box
                direction="row"
                justify="space-between"
                style={{ width: "100%" }}
              >
                <Box paddingLeft="large">
                  <Typography>
                    {offer.lender?.displayName || offer.lender?.name}
                  </Typography>
                  <Typography>
                    {
                      <TypographySecondary>
                        {formatMoney(offer.amount, country)}
                        {isSplitBalanceOffer(offer) && ` ${t("from")} `}
                      </TypographySecondary>
                    }
                  </Typography>
                </Box>
                <Box marginRight={4}>
                  <Typography>
                    {formatPercent(offer?.interestRate, country, {
                      decimals: true
                    })}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
          <Notification>
            {
              <Box direction="row">
                <Icon icon="Bell" size={3} />
                <Box marginLeft={1}>
                  <Typography>{t`offer_loan_detail.invoice_notification`}</Typography>
                </Box>
              </Box>
            }
          </Notification>
        </>
      ) : null}
    </>
  );
};

export default InvoiceOfferList;
