/**
 * Throw on errors that cancel out of a promise initiated by users.
 * Can be used to detect if users initiated cancellation.
 */
export class UserCancelError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UserCancelError";
  }
}
