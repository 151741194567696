import * as React from "react";
import { OfferType } from "../../../types/types";
import styled from "styled-components";
import { Box, Typography, theme } from "@anyfin/ui";
import ProviderAvatar from "../../../components/ProviderAvatar";
import { formatMoney, formatPercent } from "@anyfin/number-formatter";
import { useCountry } from "@anyfin/number-formatter/components";
import PillButton from "../../../components/PillButton";
import { useTranslation } from "react-i18next";
import { uniqBy } from "lodash";

const StyledPillButton = styled(PillButton)`
  height: 32px;
  padding: 0 6px 0 12px;
`;

const ProviderAvatarBox = styled(Box)`
  display: flex;
  flex-direction: row;

  & > *:not(:first-of-type) {
    margin-left: -1rem;
  }
`;

const OfferProviderBox = styled(Box)`
  padding: 1rem;
  border-radius: 20px;
  background-color: ${props => props.theme.colors.alpha.hues[100]};
`;

const OfferProviderTopBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  flex-direction: initial;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const OfferDetailsBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-flow: row wrap;
  margin-left: 1rem;

  > * {
    flex: 50%;

    &:nth-child(2n) {
      text-align: right;
    }
  }
`;

const OfferProviderBottomBox = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: row;
`;

interface OfferProviderCardProps {
  offers: Array<OfferType>;
  onClick?: () => void;
  demoProvider?: string;
}
const OfferProviderCard = ({
  offers,
  onClick,
  demoProvider
}: OfferProviderCardProps) => {
  const [country] = useCountry();
  const { t } = useTranslation("offer");
  if (!offers.length) return null;

  const multiOffers = offers.length > 1;

  const getProviderAvatars = React.useCallback(() => {
    if (!offers.length) return <></>;

    const lenders = uniqBy(
      offers.map(offer => offer.lender),
      "id"
    );

    return (
      <ProviderAvatarBox>
        {lenders.map(lender => {
          return (
            <ProviderAvatar
              provider={lender}
              showBorder={true}
              key={lender.id}
              demoProvider={demoProvider}
            />
          );
        })}
      </ProviderAvatarBox>
    );
  }, [offers]);

  if (multiOffers) {
    const offersTotal = offers.reduce((sum, o) => sum + o.amount, 0);

    return (
      <OfferProviderBox>
        <OfferProviderTopBox>
          <div>
            {getProviderAvatars()}
            <Typography size={1.125} medium style={{ marginLeft: "1rem" }}>
              {t("offer_provider_card.total_credits", {
                credits: offers.length
              })}
            </Typography>
          </div>
        </OfferProviderTopBox>

        <OfferProviderBottomBox>
          <Box>
            <Typography $as="span">{t("offer_provider_card.total")}</Typography>
            <Typography size={2}>
              {formatMoney(offersTotal, country)}
            </Typography>
          </Box>
          <StyledPillButton
            color="alpha.200"
            iconRight="ArrowRight"
            onClick={onClick}
            fontWeightMedium
          >
            {t("offer_provider_card.compare_terms_multiple")}
          </StyledPillButton>
        </OfferProviderBottomBox>
      </OfferProviderBox>
    );
  }

  const offer = offers[0];

  const showBalanceSplit =
    offer.originalAmount &&
    offer.originalAmount !== offer.amount &&
    offer.originalAmount > offer.amount;

  return (
    <OfferProviderBox>
      <OfferProviderTopBox>
        {getProviderAvatars()}
        <OfferDetailsBox>
          <div>
            <Typography size={1.125} medium>
              {demoProvider ?? offer.lender.name}
            </Typography>
            {showBalanceSplit ? (
              <Typography size={0.875} color={theme.colors.textLight.medium}>
                {t("offer_provider_card.amount_out_of", {
                  amount: offer.amount,
                  originalAmount: offer.originalAmount
                })}
              </Typography>
            ) : null}
          </div>
          <div>
            <Typography
              size={1.125}
              medium
              color={theme.colors.green.letters?.B}
            >
              {formatPercent(offer.interestRate, country, { decimals: true })}
            </Typography>
            <Typography size={0.875} style={{ textDecoration: "line-through" }}>
              {formatPercent(offer.oldInterestRate, country, {
                decimals: true
              })}
            </Typography>
          </div>
        </OfferDetailsBox>
      </OfferProviderTopBox>
      <OfferProviderBottomBox>
        <Box></Box>
        <StyledPillButton
          color="alpha.200"
          iconRight="ArrowRight"
          onClick={onClick}
          fontWeightMedium
        >
          {t("offer_provider_card.compare_terms_individual")}
        </StyledPillButton>
      </OfferProviderBottomBox>
    </OfferProviderBox>
  );
};

export default OfferProviderCard;
