import {
  ReactNode,
  useEffect,
  useState,
  useCallback,
  ChangeEvent,
  KeyboardEvent
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { gql, useMutation } from "@apollo/client";
import {
  media,
  Typography,
  Box,
  Button,
  Notification,
  theme as EVATheme
} from "@anyfin/ui";
import styled from "styled-components";
import CodeInput from "../../components/CodeInput";
import { AuthService } from "..";

const Container = styled(Box)`
  padding: 0 ${props => props.theme.spacing.sizes.xlarge}px;

  ${media.down.tablet`
    ${(props: { theme: typeof EVATheme }) =>
      `padding: 0 ${props.theme.spacing.sizes.small}px`};
  `}
`;

const TIMEOUT = 60;
const CODE_LENGTH = 6;

interface LoginOTPCodeProps {
  children?: ReactNode;
  customerId: string;
  goToNamedStep?: (step: string) => void;
}

const LoginOTPCode = (props: LoginOTPCodeProps) => {
  const { goToNamedStep, customerId, children } = props;
  const { t } = useTranslation("offer");
  const history = useHistory();
  const [otpCode, setOtpCode] = useState("");
  const [error, setError] = useState("");
  const [resendTimeout, setResendTimeout] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startLogin, { data, called, error: startLoginError }] = useMutation(
    START_OTP_LOGIN,
    {
      variables: { customerId },
      context: { service: "gateway" }
    }
  );
  const [sendOTPCode] = useMutation(SEND_OTP_CODE);

  const { sessionId, channelHint } = data?.startLoginOTP || {};
  const recoveredSessionId = AuthService.lastOtpLoginAttempt?.sessionId;

  useEffect(() => {
    if (!called && customerId && !recoveredSessionId) {
      beginLoginSession();
    }
  }, [customerId, called, recoveredSessionId]);

  useEffect(() => {
    if (sessionId) AuthService.otpLoginAttempt(sessionId, channelHint);
  }, [sessionId, channelHint]);

  const beginLoginSession = useCallback(async () => {
    try {
      if (Number.isNaN(Number.parseInt(customerId))) {
        throw new Error("Not a valid customerId!");
      }
      await startLogin();
      setResendTimeout(TIMEOUT);
    } catch (error) {
      if (error instanceof Error) {
        history.replace("./login");
        Sentry.captureException(error, { tags: { component: "LoginOTPCode" } });
      }
    }
  }, [startLogin]);

  const onResendCode = () => {
    beginLoginSession();
    setResendTimeout(TIMEOUT);
  };

  useEffect(() => {
    let resendInterval: ReturnType<typeof setTimeout>;
    if (resendTimeout > 0) {
      resendInterval = setTimeout(() => {
        setResendTimeout(resendTimeout - 1);
      }, 1000);
    }
    return () => clearInterval(resendInterval);
  }, [resendTimeout]);

  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError("");
    setOtpCode(
      e.target.value
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*?)\..*/g, "$1")
        .replace(/^0[^.]/, "0")
    );
  };

  const onSubmitOtpCode = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await sendOTPCode({
        variables: {
          code: otpCode,
          sessionId: sessionId ?? recoveredSessionId
        }
      });
      const { accessToken } = data.collect;
      AuthService.login(accessToken);
      AuthService.clearOtpLoginAttempt();
      if (goToNamedStep) {
        goToNamedStep("InfoCertPhoneNumber");
      }
    } catch (error: any) {
      console.error("ERROR ON OTP CODE: ", error);
      setError(t("pre_offer_sign.infocert.code.request_failed"));
      Sentry.captureException(error, {
        tags: {
          component: "InfoCertLoginOtp"
        },
        extra: {
          errMessage: error?.graphQLErrors?.[0]?.message
        }
      });
    } finally {
      setLoading(false);
    }
  }, [otpCode, sessionId, sendOTPCode, recoveredSessionId]);

  if (startLoginError) {
    return (
      <Container justify="space-between">
        <Box marginTop="large">
          <Notification type="danger">
            {startLoginError.toString()}
          </Notification>
        </Box>
      </Container>
    );
  }

  return (
    <Container justify="space-between">
      <Box>
        <Box paddingBottom="xxlarge">
          <Typography variant="h5">{t("auth:login_otp_header")}</Typography>
        </Box>
        {/* Placeholder for some context regarding OTP login */}
        {children && <Box paddingBottom="medium">{children}</Box>}
        <Box paddingTop={"medium"} paddingBottom="medium">
          <Typography>
            <Trans
              t={t}
              i18nKey="auth:login_otp_description"
              values={{
                email:
                  channelHint ??
                  AuthService.lastOtpLoginAttempt?.channelHint ??
                  "-"
              }}
              components={[<Typography key={0} bold />]}
            />
          </Typography>
        </Box>

        <CodeInput
          onChange={onCodeChange}
          name="otpCode"
          value={otpCode}
          fluid
          required
          autoFocus
          message={error}
          tone={error ? "error" : "natural"}
          onKeyPress={(event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter" && otpCode && !error) {
              onSubmitOtpCode();
            }
          }}
        />

        <Box paddingTop="xxlarge" paddingBottom="medium">
          <Typography>
            {!!recoveredSessionId && !sessionId
              ? t("auth:login_otp_code_found_attempt")
              : t("auth:login_otp_code_resend_text")}
          </Typography>
          <Button.Text
            bold
            height={1.6}
            // @ts-expect-error Button.Text types are wrong
            fontSize="small"
            onClick={onResendCode}
            disabled={!!resendTimeout}
          >
            {`${t("auth:login_otp_code_resend_button")}   ${
              resendTimeout ? `(${resendTimeout})` : ""
            }`}
          </Button.Text>
        </Box>
      </Box>
      <Box paddingBottom="medium">
        <Button
          onClick={onSubmitOtpCode}
          iconRight="ArrowRight"
          // @ts-expect-error Button types are wrong
          rounded
          elevated
          loading={loading}
          disabled={!!error || loading || otpCode?.length < CODE_LENGTH}
        >
          {t("pre_offer_sign.infocert.phone_number.button_label")}
        </Button>
      </Box>
    </Container>
  );
};

export default LoginOTPCode;

const START_OTP_LOGIN = gql`
  mutation startLoginOTP($customerId: ID!) {
    startLoginOTP(login: { customerId: $customerId, variant: code }) {
      sessionId
      error
      channelHint
    }
  }
`;

const SEND_OTP_CODE = gql`
  mutation collectOTPLogin($sessionId: String!, $code: String!) {
    collect: collectRefreshableOTPLogin(
      sessionId: $sessionId
      secretKey: $code
    ) {
      accessToken
      refreshToken
    }
  }
`;
