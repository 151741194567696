import React from "react";
import styled from "styled-components";
import { media, Box, Input as BaseInput, InputGroup } from "@anyfin/ui";

const InputContainer = styled(Box)`
  width: 100%;
  position: relative;
`;

const Input = styled(BaseInput)`
  text-align: center;
  font-size: 2.5rem;
  letter-spacing: 20px;
  width: 100%;

  ${media.down.tablet`
    letter-spacing: 5px;
  `}
`;

const CodeInput = props => {
  const { tone = "neutral", message, maxLength = 6 } = props;

  return (
    <InputContainer>
      <InputGroup message={message} tone={tone}>
        <Input
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          maxLength={maxLength}
          {...props}
        />
      </InputGroup>
    </InputContainer>
  );
};

export default CodeInput;
