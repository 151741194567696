import { createBrowserHistory } from "history";

import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { AuthService } from "./auth";
import { addBreadcrumb, captureException } from "@sentry/browser";
import { getCurrentLangPrefix } from "./App";

export const ApolloResponseMessages = {
  UNAUTHORIZED: "Unauthorized",
  EXPIRED_ACCESS_JWT: "Access jwt expired",
  EXPIRED_REFRESH_JWT: "Refresh jwt expired",
  SESSION_ALREADY_USED: "SESSION_ALREADY_USED"
};

// Full refresh is required otherwise login page doesn't load properly
const refreshHistory = createBrowserHistory({
  forceRefresh: true
});

const retryLink = new RetryLink();
const apiLink = createHttpLink({
  uri: `${import.meta.env.VITE_ANYFIN_API_PUBLIC_URL}/graphql`
});
const gatewayLink = createHttpLink({
  uri: `${import.meta.env.VITE_ANYFIN_GATEWAY_PUBLIC_URL}/graphql`
});
const authLink = setContext((_, { headers }) => {
  const token = AuthService.getToken();
  if (token) {
    return { headers: { ...headers, authorization: `Bearer ${token}` } };
  }
  return headers;
});

const cache = new InMemoryCache();

const unauthorizedHandler = onError(args => {
  const errors = args.graphQLErrors || [];
  const matchErr = errors.find(
    e =>
      (e.message === ApolloResponseMessages.UNAUTHORIZED) |
      ApolloResponseMessages.EXPIRED_ACCESS_JWT
  );
  if (matchErr) {
    // Don't propagate this error to the components
    args.response.errors = null;

    addBreadcrumb({
      category: "graphql",
      message: `Logout: ${matchErr.message}`
    });

    AuthService.logout();
    cache.reset();

    if (!window.location.href.includes("/login")) {
      refreshHistory.replace(`/${getCurrentLangPrefix()}/login`, {
        reason: "session_expired.generic"
      });
    }
    return;
  }

  errors.forEach(e => {
    const operation = args.operation;
    const exception = new Error(`${operation.operationName}: ${e.message}`);
    captureException(exception, {
      tags: {
        mechanism: "graphql",
        operation: operation.operationName
      },
      fingerprint: ["{{default}}", operation.operationName],
      extra: {
        extensions: JSON.stringify(e?.extensions)
      }
    });
  });
});

/**
 * Log graphQL requests as breadcrumbs for advanced logging
 * Additionally intercepts span ids for succesfull requests and marks them as finished.
 */
const logLink = new ApolloLink((operation, forward) => {
  const message = "Fired query: " + operation.operationName;
  addBreadcrumb({
    message,
    // data: operation.variables, <--- Filtered out for privacy reasons
    category: "graphql",
    level: "info"
  });

  return forward(operation);
});

const link = ApolloLink.from([
  authLink,
  unauthorizedHandler,
  logLink,
  retryLink,
  ApolloLink.split(
    operation => operation.getContext().service === "gateway", // Routes the query to the proper client
    gatewayLink,
    apiLink
  )
]);

const client = new ApolloClient({
  link,
  cache
});

export default client;
