export default async () => ({
  "sv-SE": {
    common: await import("./sv_SE/common.json"),
    home: await import("./sv_SE/home.json"),
    offer: await import("./sv_SE/offer.json"),
    refund: await import("./sv_SE/refund.json"),
    info: await import("./sv_SE/info.json"),
    auth: await import("./sv_SE/auth.json")
  },
  "en-GB": {
    common: await import("./en_GB/common.json"),
    home: await import("./en_GB/home.json"),
    offer: await import("./en_GB/offer.json"),
    refund: await import("./en_GB/refund.json"),
    auth: await import("./en_GB/auth.json"),
    info: await import("./en_GB/info.json")
  },
  "fi-FI": {
    common: await import("./fi_FI/common.json"),
    home: await import("./fi_FI/home.json"),
    offer: await import("./fi_FI/offer.json"),
    auth: await import("./fi_FI/auth.json"),
    info: await import("./fi_FI/info.json"),
    refund: await import("./fi_FI/refund.json")
  },
  "de-DE": {
    common: await import("./de_DE/common.json"),
    home: await import("./de_DE/home.json"),
    offer: await import("./de_DE/offer.json"),
    auth: await import("./de_DE/auth.json"),
    info: await import("./de_DE/info.json"),
    refund: await import("./de_DE/refund.json")
  },
  "nb-NO": {
    common: await import("./nb_NO/common.json"),
    home: await import("./nb_NO/home.json"),
    offer: await import("./nb_NO/offer.json"),
    auth: await import("./nb_NO/auth.json"),
    info: await import("./nb_NO/info.json")
  }
});
