import { media, Box, Color, Curve, Typography, theme } from "@anyfin/ui";
import styled from "styled-components";
import { Money, useCountry } from "@anyfin/number-formatter/components";
import useGeneratePaymentPlan from "../hooks/useGeneratePaymentPlan";
import Section from "../../../components/Section";
import { parseISO } from "date-fns";
import { formatDate } from "@anyfin/number-formatter";
import { useTranslation } from "react-i18next";

import OfferFlag from "../../../assets/offer/offer_flag.svg";
import { OfferType } from "../../../types/types";
import { OfferTotalsType } from "../types";

const InvoiceSummarySection = styled(Section)`
  position: relative;
  margin-top: 200px;

  ${media.down.phone`
    margin-top: 170px;
  `}
`;

const OfferFlagImage = styled.img`
  position: absolute;
  right: 50px;
  height: 250px;
  top: -200px;
  aspect-ratio: ${199 / 241};

  ${media.down.phone`
    right: 30px;
    height: 200px;
    top: -170px;
  `};
`;

const InvoiceSummaryBox = styled(Box)`
  background-color: ${props => props.theme.colors.yellow.letters?.B};
  padding: 3rem 1.5rem;
`;

interface InvoiceBoxProps {
  elevated?: boolean;
}

const InvoiceBox = styled(Box)<InvoiceBoxProps>`
  border-radius: ${({ theme }) => theme.shape.borderRadius.medium}px;
  max-width: 230px;
  flex: 1;
  padding: 1em;
  min-height: 182px;
  scroll-snap-align: start;

  &:not(:last-child) {
    margin-right: 1em;
  }

  ${media.down.desktop`
    flex: 1 0 66.13%;
    max-width: 360px;
  `}
`;

const InvoiceList = styled.div`
  display: flex;
  padding: 0 0.5rem;

  background-color: ${props => props.theme.colors.yellow.letters?.B};
  scroll-padding-left: 1em;
  margin: 0 -1.5rem;

  ${media.down.desktop`
    overflow-x: scroll;
    scroll-snap-type: x mandatory;

    /* Give the scrollbar some room */
    margin-bottom: -1rem;
    padding-bottom: 1rem;
  `};
`;

const DatePill = styled.span`
  width: fit-content;
  padding: 3px 6px;
  border-radius: 20px;
  background-color: ${props => props.theme.colors.alpha.hues?.[100]};
`;

const Spacer = styled.span`
  height: 1rem;
  width: 100%;
`;

interface InvoiceSummaryProps {
  offers: Array<OfferType>;
  totals: OfferTotalsType;
  showDiscountImpact: boolean;
  offersToken?: string;
}

const InvoiceSummary = ({
  offers,
  offersToken,
  totals,
  showDiscountImpact
}: InvoiceSummaryProps) => {
  const { t } = useTranslation("offer");
  const [country] = useCountry();
  const { paymentPlan } = useGeneratePaymentPlan({
    oneOffPaymentAmount: showDiscountImpact ? totals.discountAmount : 0,
    applicationIds: offers.map(offer => offer.applicationId),
    count: 3,
    offersToken: offersToken
  });

  return (
    <InvoiceSummarySection>
      <OfferFlagImage src={OfferFlag}></OfferFlagImage>
      <Curve
        color={theme.colors.yellow.letters?.B as Color}
        direction="up"
        size="small"
        type="overlay"
      ></Curve>
      <InvoiceSummaryBox>
        <Typography.H4>{t("invoice_summary.title")}</Typography.H4>
        <Spacer></Spacer>
        <InvoiceList>
          {paymentPlan?.map((payment, i) => {
            const offerDueDate = parseISO(payment.date);
            return offerDueDate && payment.amount ? (
              <InvoiceBox
                color={"white"}
                key={i}
                direction="column"
                justify="space-between"
              >
                <DatePill>
                  <Typography size={1}>
                    {formatDate(offerDueDate, country, {
                      date: "medium",
                      includeYear: false
                    })}
                  </Typography>
                </DatePill>
                <Typography size={2.5}>
                  <Money value={payment.amount} />
                </Typography>
              </InvoiceBox>
            ) : null;
          })}
        </InvoiceList>
        <Spacer></Spacer>
        <Typography size={1}>{t("invoice_summary.disclaimer")}</Typography>
      </InvoiceSummaryBox>
    </InvoiceSummarySection>
  );
};

export default InvoiceSummary;
