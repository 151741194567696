import React from "react";
import { Box } from "@anyfin/ui";

const CopyContainer = ({ children, ...rest }) => (
  <Box paddingLeft="medium" paddingRight="medium" {...rest}>
    {children}
  </Box>
);

export default CopyContainer;
