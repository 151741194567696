import React from "react";
import PropTypes from "prop-types";
import { withUser } from "../auth";
import Helmet from "react-helmet";
import styled from "styled-components";

import { Ui } from "@anyfin/ui";
import SiteHeader from "./SiteHeader";
import SiteFooter from "./SiteFooter";

const ContentWrapper = styled.div`
  padding-top: 72px;
`;

class Layout extends React.Component {
  static propTypes = {
    basicHeader: PropTypes.bool,
    noHeader: PropTypes.bool,
    noFooter: PropTypes.bool,
    countrySelect: PropTypes.bool
  };

  static defaultProps = {
    basicHeader: false,
    noHeader: false,
    noFooter: false,
    countrySelect: false
  };

  render() {
    const {
      children,
      user,
      basicHeader,
      noFooter,
      noHeader,
      countrySelect
    } = this.props;
    return (
      <Ui>
        <Helmet defaultTitle="Anyfin" titleTemplate="Anyfin - %s" />

        {!noHeader && <SiteHeader user={user} basic={basicHeader} />}
        {!noHeader ? <ContentWrapper>{children}</ContentWrapper> : children}
        {!noFooter && <SiteFooter countrySelect={countrySelect} />}
      </Ui>
    );
  }
}

export default withUser(Layout);
