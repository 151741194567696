import { Box, Button } from "@anyfin/ui";
import * as Sentry from "@sentry/browser";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useCollectBankIDLogin,
  useStartLoginBankID
} from "../CombinedOfferPage/hooks/useLoginBankID";
import BankIDQRModal from "../../components/BankIDQRModal";

interface LoginBankIDProps {
  onSuccess: (token: string) => void;
  children?: ReactNode;
}

type BankIdViewProps = {
  openSameDevice: () => void;
  toggleModal: () => void;
};

const openUrl = (url: string) => {
  window.location.href = url;
};

const DesktopView = (props: BankIdViewProps) => {
  const [t] = useTranslation("auth");
  return (
    <Box>
      <Box paddingLeft={0} paddingRight={0} paddingTop={"large"}>
        <Button
          onClick={props.toggleModal}
          // @ts-expect-error Button types are wrong
          rounded
          iconRight={"ArrowRight"}
          iconLeft={"BankidFilled"}
        >
          {t("bankid.login")}
        </Button>
      </Box>
      <Box paddingLeft={0} paddingRight={0}>
        <Button
          onClick={props.openSameDevice}
          color={"white"}
          iconRight={"ArrowRight"}
          iconLeft={"BankidFilled"}
        >
          {t("bankid.bankid_on_same_device")}
        </Button>
      </Box>
    </Box>
  );
};

const MobileView = (props: BankIdViewProps) => {
  const [t] = useTranslation("auth");
  return (
    <Box>
      <Box paddingLeft={0} paddingRight={0} paddingTop={"large"}>
        <Button
          onClick={props.openSameDevice}
          // @ts-expect-error Button types are wrong
          rounded
          iconRight={"ArrowRight"}
          iconLeft={"BankidFilled"}
        >
          {t("bankid.bankid_on_same_device")}
        </Button>
      </Box>
      <Box paddingLeft={0} paddingRight={0}>
        <Button
          onClick={props.toggleModal}
          iconRight={"ArrowRight"}
          iconLeft={"BankidFilled"}
          color={"white"}
        >
          {t("bankid.bankid_on_another_device")}
        </Button>
      </Box>
    </Box>
  );
};

const LoginBankID = ({ onSuccess, children }: LoginBankIDProps) => {
  const [showQRModal, setshowQRModal] = useState(false);
  const isMobile = () => /Mobi/.test(navigator.userAgent);

  const isPhone = isMobile();
  const toggleModal = async () => {
    if (!showQRModal) {
      getLoginResults();
      setshowQRModal(true);
    } else {
      skipCollecting();
      setshowQRModal(false);
    }
  };

  type Token = {
    refreshToken: string;
    accessToken: string;
  };

  type LoginBankIDResult = {
    token: Token;
    qrContent: string;
    status: String;
  };

  const [startLogin, startLoginData] = useStartLoginBankID();

  const {
    collectTillFinished,
    mutation: collectLogin,
    skipCollecting
  } = useCollectBankIDLogin();

  const getLoginResults = async (sameDevice: boolean = false) => {
    try {
      const { data } = await startLogin();
      if (data) {
        const { orderRef } = data.startLoginBankID;

        if (sameDevice) {
          const bankIdUrl = `bankid:///?autostarttoken=${data.startLoginBankID.autoStartToken}&redirect=null`;
          openUrl(bankIdUrl);
        }

        const collect = await collectTillFinished(orderRef);
        if (collect?.data) {
          const loginResult = collect.data
            .collectLoginBankIDWithQR as LoginBankIDResult;

          setshowQRModal(false);
          onSuccess(loginResult?.token?.accessToken);
        }
      }
    } catch (error) {
      Sentry.captureException(error, { tags: { component: "LoginBankID" } });
    }
  };

  useEffect(() => {
    if (collectLogin.error?.message.includes("startFailed")) {
      getLoginResults();
    }
  }, [collectLogin.error?.message]);

  const collectLoginData = collectLogin?.data?.collectLoginBankIDWithQR;
  const qrContent = collectLoginData?.qrContent
    ? collectLoginData?.qrContent
    : startLoginData?.data?.startLoginBankID?.qrContent;

  const autoStartToken = startLoginData?.data?.startLoginBankID?.autoStartToken;

  return (
    <Box>
      {children}
      {isPhone ? (
        <MobileView
          openSameDevice={() => getLoginResults(true)}
          toggleModal={toggleModal}
        />
      ) : (
        <DesktopView
          openSameDevice={() => getLoginResults(true)}
          toggleModal={toggleModal}
        />
      )}
      <BankIDQRModal
        qrContent={qrContent}
        autoStartToken={autoStartToken}
        toggleModal={toggleModal}
        showQRModal={showQRModal}
      />
    </Box>
  );
};

export default LoginBankID;
