import React, { useCallback, useEffect, useState } from "react";

import { identify } from "../utils/segment";
import AuthService from "./AuthService";
export { AuthService };

export const AuthContext = React.createContext();

export function provideAuth(auth) {
  return Component =>
    function AuthProvider(props) {
      const [loggedIn, setLoggedIn] = useState(false);

      const onChange = useCallback(
        v => {
          if (loggedIn === v) return;

          setLoggedIn(v);
          if (!auth.isAdmin()) {
            identify(auth.activeUser);
          }
        },
        [setLoggedIn]
      );

      useEffect(() => {
        auth
          .init({ onChange })
          .then(user => {
            onChange(true);
          })
          .catch(e => {
            auth.clearTokens();
          });
      }, [onChange]);

      return (
        <AuthContext.Provider
          value={{
            loggedIn,
            activeUser: auth.activeUser,
            isAdmin: auth.isAdmin(),
            login: auth.login
          }}
        >
          <Component {...props} />
        </AuthContext.Provider>
      );
    };
}

export function withAuth(Component) {
  return function WithAuthHOC(props) {
    return (
      <AuthContext.Consumer>
        {auth => <Component auth={auth} {...props} />}
      </AuthContext.Consumer>
    );
  };
}

export const withUser = Component => {
  return function WithUserHOC(props) {
    return (
      <AuthContext.Consumer>
        {auth => (
          <Component
            loggedIn={auth.loggedIn}
            user={auth.activeUser}
            isAdmin={auth.isAdmin}
            {...props}
          />
        )}
      </AuthContext.Consumer>
    );
  };
};
