import React from "react";
import { withRouter } from "react-router-dom";
import { TopMenu } from "@anyfin/ui";

import { homeUrl } from "../utils/routes";
import { useTranslation } from "react-i18next";
import { downloadApp } from "../utils/client";
import { localeRegex } from "../utils/i18n";

const ANYFIN_URL = import.meta.env.VITE_ANYFIN_WEB_URL;

const SiteHeader = ({ match, user, ...restProps }) => {
  const { t } = useTranslation();
  const { url } = match;
  const lang = url.match(localeRegex)[1];

  const publicLinks = [
    {
      displayName: t("navigation.login"),
      url: "/login"
    }
  ];

  const privateLinks = [
    {
      name: t("navigation.mypages"),
      href: homeUrl(match)
    },
    {
      name: t("navigation.logout"),
      href: url.match(localeRegex) && `/${lang}/logout`
    }
  ];

  const routes = [
    {
      name: t("navigation.home"),
      href: ANYFIN_URL
    },
    {
      name: t("navigation.faq"),
      href: `${ANYFIN_URL}/faq`
    },
    ...(user ? privateLinks : publicLinks)
  ];

  return (
    <TopMenu
      navigation={{
        isActive: () => false,
        routes
      }}
      cta={{
        iconRight: "ArrowRight",
        text: t("appDownload.button"),
        onClick: downloadApp
      }}
      {...restProps}
    />
  );
};

export default withRouter(SiteHeader);
