import { Box, Button, Typography } from "@anyfin/ui";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Loader } from "./Loader";
import Modal from "./Modal";

const openUrl = (url: string) => {
  window.location.href = url;
};

const QRModal = styled(Modal)`
  border-radius: 20px;
  max-width: 400px;
`;

interface LoginBankIDProps {
  showQRModal: boolean;
  toggleModal: () => Promise<void>;
  autoStartToken?: string;
  qrContent?: string;
  modalTitle?: string;
}

const BankIDQRModal = ({
  showQRModal,
  toggleModal,
  autoStartToken,
  qrContent,
  modalTitle
}: LoginBankIDProps) => {
  const bankIdUrl = autoStartToken
    ? `bankid:///?autostarttoken=${autoStartToken}&redirect=null`
    : null;

  const [t] = useTranslation("auth");

  return (
    <QRModal
      open={showQRModal}
      toggleModal={toggleModal}
      title={modalTitle || t("bankid.button")}
    >
      <Box margin="small">
        <Typography>{t("bankid.instruction_desktop_open")}</Typography>
        <Typography>{t("bankid.instruction_desktop_tap")}</Typography>
        <Typography>{t("bankid.instruction_desktop_camera")}</Typography>
        <Box align="center" padding="medium">
          {qrContent ? <QRCodeSVG size={250} value={qrContent} /> : <Loader />}
        </Box>
        <Box style={{ alignItems: "flex-end" }}>
          <Button.Text
            onClick={() => openUrl(bankIdUrl ?? "")}
            iconRight={"ArrowRight"}
          >
            {t("bankid.bankid_on_same_device")}
          </Button.Text>
        </Box>
      </Box>
    </QRModal>
  );
};

export default BankIDQRModal;
