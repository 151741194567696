import styled from "styled-components";
import { Box } from "@anyfin/ui";

const LoaderContainer = styled.svg`
  animation: rotate 2s linear infinite;
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  & .path {
    stroke: #000000;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export const Loader = props => {
  const size = props.size ?? 50;
  return (
    <Box {...props}>
      <LoaderContainer size={size} viewBox={`0 0 ${size} ${size}`}>
        <circle
          className="path"
          cx={size / 2}
          cy={size / 2}
          r="20"
          fill="none"
          strokeWidth="4"
        />
      </LoaderContainer>
    </Box>
  );
};
