import { gql } from "@apollo/client";
import { withMutation } from "@apollo/client/react/hoc";
import { useState, useEffect, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import compose from "lodash/flowRight";
import { useTranslation } from "react-i18next";
import { Loader } from "../../components/Loader";
import { Input, InputGroup, Button, Notification, Box } from "@anyfin/ui";

import { useCountry } from "@anyfin/number-formatter/components";

const LoginOTP = ({ startLogin, collectLogin, onSuccess }) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation("auth");
  const [inProgress, setInProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [collectingToken, setCollectingToken] = useState(false);
  const [channelValue, setChannelValue] = useState(null);
  const [identifier, setIdentifier] = useState(null);
  const [identifierRequired, setIdentifierRequired] = useState(false);
  const [country] = useCountry();

  // Clicked on link from email, formatted /<lang>/login?session=...&key=...
  useEffect(() => {
    const session = location.search.match(/session=([\w+-]+)/);
    const key = location.search.match(/key=([\w+-]+)/);
    if (session && key) {
      setCollectingToken(true);
      collectLogin({
        variables: { session: session[1], key: key[1] }
      })
        .then(({ data: { collectLoginOTP } }) => {
          const token = collectLoginOTP?.accessToken;
          if (token) {
            onSuccess(token);
          }
        })
        .catch(e => {
          setCollectingToken(false);
        });
    }
  }, [collectLogin, history, location]);

  const onSubmit = async () => {
    setInProgress(true);
    await startLogin({
      variables: {
        countryCode: country,
        ssn: identifier,
        channelValue
      }
    })
      .then(({ data }) => {
        setError(null);
        if (data?.startLoginOTP?.error === "multiple_customers_for_email") {
          setIdentifierRequired(true);
          return;
        }
        setSuccess(true);
      })
      .catch(e => {
        setError(t("otp.errors.generic"));
      });
    setInProgress(false);
  };

  if (collectingToken) {
    return <Loader />;
  }
  if (success) {
    return <Notification iconLeft="Inbox">{t("otp.success")}</Notification>;
  }

  return (
    <Fragment>
      {error && (
        <Box marginBottom="large">
          <Notification iconLeft="Alert" type="danger">
            {error}
          </Notification>
        </Box>
      )}
      <form onSubmit={e => e.preventDefault() || onSubmit(startLogin)}>
        <InputGroup label={t("otp.email_label")}>
          <Input
            onChange={e => setChannelValue(e.target.value)}
            name="email"
            value={channelValue}
            fluid
            required
          />
        </InputGroup>

        {identifierRequired ? (
          <InputGroup label={t("otp.personal_identifier_label")}>
            <Input
              onChange={e => setIdentifier(e.target.value)}
              name="identifier"
              placeholder={t("otp.personal_identifier_placeholder")}
              value={identifier}
              fluid
              pattern="[0-9]{6}[-+A].{4}"
              required
            />
          </InputGroup>
        ) : null}

        <Box marginTop="large">
          <Button
            iconRight="ArrowRight"
            type="submit"
            fluid
            disabled={inProgress}
            rounded
            elevated
          >
            {t("otp.button")}
          </Button>
        </Box>
      </form>
    </Fragment>
  );
};

const startOTPLoginMutation = withMutation(
  gql`
    mutation startLoginOTP(
      $countryCode: String!
      $channelValue: String!
      $ssn: String
    ) {
      startLoginOTP(
        login: {
          countryCode: $countryCode
          channelType: "email"
          channelValue: $channelValue
          ssn: $ssn
        }
      ) {
        sessionId
        error
      }
    }
  `,
  { name: "startLogin", options: { context: { service: "gateway" } } }
);

const collectOTPLoginMutation = withMutation(
  gql`
    mutation collectLoginOTP($session: String!, $key: String!) {
      collectLoginOTP(sessionId: $session, secretKey: $key) {
        tokenType
        accessToken
      }
    }
  `,
  { name: "collectLogin", options: { context: { service: "gateway" } } }
);

export default compose(
  startOTPLoginMutation,
  collectOTPLoginMutation
)(LoginOTP);
