import * as Sentry from "@sentry/browser";

export class LocalStorageService {
  /**
   * Returns item from asyncstorage
   * Taking into account expiration information on the object
   *
   * @param key Key to the item to fetch
   * @returns null if not found or expired, anything else if found
   */
  getItem(key: string) {
    const value = localStorage.getItem(key);

    if (!value) return null;

    try {
      const parsedValue = JSON.parse(value);

      // If the type is not an object, it cannot have expiration date, so just return it
      if (typeof parsedValue !== "object") return parsedValue;

      // If no expiration date or no value key, return object as-is
      if (!parsedValue.expiresAt || parsedValue.value === null)
        return parsedValue;

      // If expired, remove from storage and return null
      if (parsedValue.expiresAt < Date.now()) {
        localStorage.removeItem(key);
        return null;
      }

      // Return the value, since it hasn't expired yet
      return parsedValue.value;
    } catch (e) {
      // Log to sentry
      Sentry.captureException(e, {
        extra: {
          key
        },
        tags: { service: "LocalStorageService" }
      });
    }

    // JSON parse failed for some reason? Just return the value
    return value;
  }

  /**
   * Set items in async storage
   * @param key {String} Key to write to
   * @param value {Any} Value to store
   * @param expiresAt {Date} Expiration date
   */
  setItem(key: string, value: any, expiresAt: Date | null = null) {
    let entry = value;

    // If expiration date has been set, encode it into storage
    if (expiresAt && expiresAt instanceof Date) {
      // {value: Any, _expires: Timestamp}
      entry = { value, expiresAt: expiresAt.getTime() };
    }

    localStorage.setItem(key, JSON.stringify(entry));
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }
}
