import React from "react";
import { media, LogoMark } from "@anyfin/ui";
import styled, { css } from "styled-components";
import PillButton from "./PillButton";
import Section from "./Section";
import { useTranslation } from "react-i18next";
import { APP_STORE_TRACKING_URL } from "../utils/client";
import trustseal from "../assets/trustseal.jpg";
import { useCountry } from "@anyfin/number-formatter/components";

const TRUSTSEAL_URL = import.meta.env.VITE_TRUSTSEAL_URL;

export const HEADER_HEIGHT = 7.5;
export const HEADER_HEIGHT_MOBILE = 3.5;

const Container = styled(Section)`
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  min-height: ${HEADER_HEIGHT_MOBILE}rem;
  padding: 0 1rem;

  ${media.up.desktop(css`
    min-height: ${HEADER_HEIGHT}rem;
  `)};
`;

const StyledLogo = styled(LogoMark)`
  height: 35px;
  width: 36px;

  ${media.up.desktop`
    height: 64px;
    width: 64px;
  `};
`;

const TrustsealWrapper = styled.div`
  width: 95px;
  height: 50px;
  cursor: pointer;
  ${media.down.desktop`
    margin-top: 10px;
  `};
`;

const LogoBar = ({ showGetAppButton }: { showGetAppButton: boolean }) => {
  const { t } = useTranslation("offer");

  const navToApp = () => {
    window.location.href = APP_STORE_TRACKING_URL;
  };

  // @ts-ignore
  const [country] = useCountry();

  return (
    <Container>
      <StyledLogo />
      {showGetAppButton ? (
        <PillButton iconRight="ArrowRight" onClick={navToApp}>
          {t("success.open_app")}
        </PillButton>
      ) : country === "DE" ? (
        <TrustsealWrapper onClick={() => window.open(TRUSTSEAL_URL, "_blank")}>
          <img
            src={trustseal}
            width="100%"
            height="100%"
            alt="Trustseal Logo"
          />
        </TrustsealWrapper>
      ) : null}
    </Container>
  );
};

export default LogoBar;
