import { gql, MutationHookOptions, useMutation } from "@apollo/client";
import { useCallback, useRef } from "react";
import { sleep } from "../../../utils/time";

const START_LOGIN = gql`
  mutation startLogin($personalIdentifier: String) {
    startLoginBankID(personalIdentifier: $personalIdentifier) {
      orderRef
      autoStartToken
      qrContent
    }
  }
`;

const COLLECT_LOGIN_QR = gql`
  mutation CollectLogin($sessionId: String!) {
    collectLoginBankIDWithQR(sessionId: $sessionId) {
      token {
        accessToken
        refreshToken
        tokenType
        accessTokenExpiresIn
        refreshTokenExpiresIn
      }
      qrContent
      status
    }
  }
`;
type StartLoginBankIDVariables = {
  personalIdentifier: string;
};
type StartLoginBankIDResult = {
  startLoginBankID: {
    orderRef: string;
    autoStartToken: string;
    qrContent: any;
  };
};
export function useStartLoginBankID(
  options?: MutationHookOptions<
    StartLoginBankIDResult,
    StartLoginBankIDVariables
  >
) {
  return useMutation(START_LOGIN, {
    ...options,
    context: { ...options?.context, service: "gateway" }
  });
}

export function useCollectBankIDLogin(sessionId?: string) {
  const [collect, mutation] = useMutation(COLLECT_LOGIN_QR, {
    context: { service: "gateway" },
    variables: { sessionId }
  });

  const skipped = useRef<boolean>(false);

  const collectTillFinished = useCallback(
    async (
      sessionId: string,
      pollingOptions?: { timeoutInMs?: number; intervalInMs?: number }
    ) => {
      skipped.current = false;
      const operationTimeout = pollingOptions?.timeoutInMs || 10 * 60 * 1000;
      const intervalInMs = pollingOptions?.intervalInMs || 1000;
      const startTime = Date.now();

      while (Date.now() - startTime < operationTimeout) {
        if (skipped.current) {
          return;
        }
        const latestMutation = await collect({
          variables: { sessionId }
        });
        const status = latestMutation.data?.collectLoginBankIDWithQR?.status;
        if (status && status !== "pending") {
          return latestMutation;
        }
        await sleep(intervalInMs);
      }
      throw new Error(`Timedout after ${operationTimeout}ms`);
    },
    [collect, skipped]
  );

  const skipCollecting = useCallback(() => {
    skipped.current = true;
  }, []);

  return { collect, collectTillFinished, mutation, skipCollecting };
}
