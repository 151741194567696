import React, { useState, useEffect, useRef } from "react";
import { Manager, Reference, Popper } from "react-popper";

/**
 * Simple dropdown, only takes care of toggling list on click
 * @param {*} props
 */
const Dropdown = props => {
  const [visible, setVisible] = useState(false);
  const popperRef = useRef(null);

  useEffect(() => {
    const handleClick = function (e) {
      if (popperRef.current && !popperRef.current.contains(e.target)) {
        setVisible(false);
      }
    };
    const cleanup = () => window.removeEventListener("click", handleClick);
    if (visible === true) {
      setTimeout(() => {
        window.addEventListener("click", handleClick);
      }, 25);
    } else {
      cleanup();
    }
    return cleanup;
  }, [visible, popperRef]);

  // Allow override of the Trigger and List with styled-components
  const TriggerComponent = props.triggerComponent;
  const ListComponent = props.listComponent;

  // Figure out if an option has been selected, or fallback to "title" prop
  const selected =
    props.data && props.data.find(({ key }) => key === props.value);

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <TriggerComponent
            onClickCapture={e => {
              e.stopPropagation();
              setVisible(!visible);
            }}
            type="button"
            ref={ref} // Swap out to "ref" on styled-components 4.x
            visible={visible}
            minWidth={props.minWidth}
          >
            {(selected && props.renderItem(selected)) || props.title}
          </TriggerComponent>
        )}
      </Reference>
      {visible && (
        <Popper
          placement={props.placement}
          innerRef={node => (popperRef.current = node)}
        >
          {({ ref, style, placement }) => (
            <div
              ref={ref}
              style={{ ...style, zIndex: 9 }}
              data-placement={placement}
            >
              <ListComponent minWidth={props.minWidth}>
                {props.renderItem
                  ? props.data.map(props.renderItem)
                  : props.children}
              </ListComponent>
            </div>
          )}
        </Popper>
      )}
    </Manager>
  );
};
Dropdown.defaultProps = {
  listComponent: "div",
  minWidth: "auto",
  placement: "bottom-start",
  title: "Select",
  triggerComponent: "button"
};

export default Dropdown;
