import React from "react";
import CopyContainer from "../components/CopyContainer";
import { Typography, Box, Button, Color, Curve, theme } from "@anyfin/ui";
import { useTranslation } from "react-i18next";
import LogoBar from "../components/LogoBar";
import Section from "../components/Section";
import styled from "styled-components";

import OfferSuccessImage from "../assets/offer/offer_success.svg";
import { useHistory } from "react-router-dom";

const ImageContainer = styled(Box)`
  background-color: ${props => props.theme.colors.yellow.letters?.B};
  padding: 2.5rem 0.5rem 0 0.5rem;
`;

const StyledImage = styled.img`
  min-width: 240px;
  max-width: 400px;
  margin: 0 auto;
`;

const OfferSuccessPage = () => {
  const { t } = useTranslation("offer");
  const history = useHistory();

  return (
    <React.Fragment>
      <LogoBar showGetAppButton />
      <Section>
        <Box>
          <ImageContainer>
            <StyledImage src={OfferSuccessImage}></StyledImage>
          </ImageContainer>
          <Curve
            color={theme.colors.yellow.letters?.B as Color}
            direction="down"
            size="large"
          ></Curve>
        </Box>

        <CopyContainer marginTop={2.5} marginBottom={1}>
          <Typography.H4>{t("success.title")}</Typography.H4>
        </CopyContainer>
        <CopyContainer marginBottom={3}>
          <Typography size={1.125}>{t("success.content")}</Typography>
        </CopyContainer>

        <Box paddingLeft={0.5} paddingRight={0.5} marginBottom={5}>
          <Button
            rightAlignedTitle
            iconRight="ArrowRight"
            onClick={() => history.replace("home")}
          >
            {t("success.clear")}
          </Button>
        </Box>
      </Section>
    </React.Fragment>
  );
};

export default OfferSuccessPage;
