import React from "react";
import { Box, Typography } from "@anyfin/ui";
import { useTranslation } from "react-i18next";
import { useCountry } from "@anyfin/number-formatter/components";
import styled from "styled-components";

import Section from "../../../components/Section";
import CopyContainer from "../../../components/CopyContainer";
import RoundIcon from "../../../components/RoundIcon";
import { COUNTRY_CODES } from "../../../utils/countries";

const StyledSection = styled(Section)`
  padding: 1.5rem;
`;

const OfferFeatures = () => {
  const { t } = useTranslation("offer");
  const [country] = useCountry();

  const FEATURES: Record<
    COUNTRY_CODES,
    Array<{ title: string; description: string }>
  > = {
    SE: [
      {
        title: t("offer_features.title_1"),
        description: t("offer_features.description_1")
      },
      {
        title: t("offer_features.title_2"),
        description: t("offer_features.description_2")
      },
      {
        title: t("offer_features.title_3"),
        description: t("offer_features.description_3")
      }
    ],
    FI: [
      {
        title: t("offer_features.title_1"),
        description: t("offer_features.description_1")
      },
      {
        title: t("offer_features.title_2"),
        description: t("offer_features.description_2")
      },
      {
        title: t("offer_features.title_3"),
        description: t("offer_features.description_3")
      }
    ],
    // @TODO update Germany copy and illustrations
    DE: [
      {
        title: t("offer_features.title_1"),
        description: t("offer_features.description_1")
      },
      {
        title: t("offer_features.title_2"),
        description: t("offer_features.description_2")
      },
      {
        title: t("offer_features.title_3"),
        description: t("offer_features.description_3")
      }
    ],
    NO: [
      {
        title: t("offer_features.title_1"),
        description: t("offer_features.description_1")
      },
      {
        title: t("offer_features.title_2"),
        description: t("offer_features.description_2")
      },
      {
        title: t("offer_features.title_3"),
        description: t("offer_features.description_3")
      }
    ]
  };

  return (
    <StyledSection>
      <Typography.H4 style={{ marginBottom: "2rem" }}>
        {t("offer_features.title")}
      </Typography.H4>
      {FEATURES[country as COUNTRY_CODES].map((feature, index) => (
        <OfferFeatureItem
          key={index}
          title={feature.title}
          description={feature.description}
        />
      ))}
    </StyledSection>
  );
};

const OfferFeatureItemBox = styled(Box)`
  display: flex;
  flex-direction: row;
  :not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
`;

interface OfferFeatureItemProps {
  title: string;
  description: string;
}

const OfferFeatureItem = ({ title, description }: OfferFeatureItemProps) => {
  return (
    <OfferFeatureItemBox>
      <RoundIcon
        icon="Check"
        iconSize={1.625}
        size={2.5}
        background="#00995c1a"
      />
      <CopyContainer>
        <Typography size={1} medium>
          {title}
        </Typography>
        <Typography size={0.875}>{description}</Typography>
      </CopyContainer>
    </OfferFeatureItemBox>
  );
};

export default OfferFeatures;
