import React from "react";
import { Trans } from "react-i18next";
import { Typography, Notification, Box } from "@anyfin/ui";

import LogoBar from "../../components/LogoBar";
import Section from "../../components/Section";
import { LocaleCodeURL } from "../../utils/i18n";

const { VITE_ANYFIN_WEB_URL } = import.meta.env;

const localizedWebUrl = {
  [LocaleCodeURL.sv_SE]: {
    link: VITE_ANYFIN_WEB_URL + "/sv_SE?app=intro&provider="
  },
  [LocaleCodeURL.de_DE]: {
    link: VITE_ANYFIN_WEB_URL + "/de_DE?app=intro&provider="
  },
  [LocaleCodeURL.fi_FI]: {
    link: VITE_ANYFIN_WEB_URL + "/fi_FI?app=intro&provider="
  },
  [LocaleCodeURL.nb_NO]: {
    link: VITE_ANYFIN_WEB_URL + "/nb_NO?app=intro"
  }
};

const NoOfferPage = ({ match }) => {
  return (
    <Section>
      <LogoBar />
      <Box marginTop="xxlarge" marginBottom={7} padding="medium">
        <Notification iconLeft="Alert">
          <Trans
            i18nKey="offer:missing_offer_message_default"
            defaults="offer:missing_offer_message_default"
            components={[
              <Typography.Link
                key={0}
                href={localizedWebUrl[match.params.locale]?.link}
              >
                anyfin.com
              </Typography.Link>
            ]}
          />
        </Notification>
      </Box>
    </Section>
  );
};

export default NoOfferPage;
