import styled from "styled-components";
import { OfferType } from "../../../../types/types";
import TableCompare from "./TableCompare";
import { getOfferItems } from "./OfferDetailsModals";
import {
  CountryCode,
  formatMoney,
  formatPercent
} from "@anyfin/number-formatter";
import { TFunction } from "i18next";
import ProviderCard from "../../../../components/ProviderCard";
import { Box, Typography, theme } from "@anyfin/ui";
import React from "react";

interface OfferDetailsItemProps {
  offer: OfferType;
  country: CountryCode;
  t: TFunction;
  showAsFlatListItem?: boolean;
}

interface StyledProviderCardProps {
  showBackgroundColor: boolean;
}

const StyledProviderCard = styled(ProviderCard)<StyledProviderCardProps>`
  background: ${props =>
    props.showBackgroundColor
      ? props.theme.colors.alpha.hues?.[100]
      : "transparent"};
  border-radius: 1.25rem 1.25rem 0 0;

  &:hover {
    background: ${props =>
      props.showBackgroundColor
        ? props.theme.colors.alpha.hues?.[100]
        : "transparent"};
  }

  transition: all 300ms ease-in-out;
`;

interface StyledBoxProps {
  isToggled: boolean;
  hideBackgroundColor: boolean;
}

const StyledBox = styled(Box)<StyledBoxProps>`
  max-height: ${props => (props.isToggled ? "1000px" : 0)};
  background: ${props =>
    props.isToggled && !props.hideBackgroundColor
      ? props.theme.colors.alpha.hues?.[100]
      : "transparent"};
  border-radius: 0 0 1.25rem 1.25rem;
  overflow: hidden;

  &:not(:last-of-type) {
    margin-bottom: ${props => (props.isToggled ? "0.5rem" : "0")};
  }

  transition: all 300ms ease-in-out;
`;

const LabelRight = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const OfferDetailsItem = ({
  offer,
  country,
  t,
  showAsFlatListItem = false
}: OfferDetailsItemProps) => {
  const [isToggled, setIsToggled] = React.useState(showAsFlatListItem || false);

  return (
    <>
      <StyledProviderCard
        provider={offer.lender}
        description={formatMoney(offer.amount, country)}
        labelRight={
          <LabelRight>
            <Typography
              size={1}
              style={{ textDecoration: "line-through" }}
              color={theme.colors.textLight.medium}
            >
              {formatPercent(offer.oldInterestRate, country)}
            </Typography>
            <Typography size={1} color="#199F72">
              {formatPercent(offer.interestRate, country)}
            </Typography>
          </LabelRight>
        }
        iconRight={
          showAsFlatListItem ? null : isToggled ? "ChevronUp" : "ChevronDown"
        }
        showAvatarBorder={undefined}
        isDemo={undefined}
        onClick={() => setIsToggled(!isToggled)}
        showBackgroundColor={showAsFlatListItem ? false : isToggled}
      ></StyledProviderCard>
      <StyledBox
        key={offer.id}
        isToggled={isToggled}
        paddingLeft={4}
        paddingBottom={1}
        paddingTop={1}
        hideBackgroundColor={showAsFlatListItem}
      >
        <TableCompare
          items={getOfferItems(offer, country, t, true)}
          contentSize={1}
          hideRowBorder={showAsFlatListItem}
        ></TableCompare>
      </StyledBox>
    </>
  );
};

export default OfferDetailsItem;
